import React from 'react';

// Types
import { KlarnaContainerProps as LazyComponentProps } from './KlarnaContainer.types';

// Components
import withSuspense, { lazyRetry } from '../../suspense';

const LazyComponent = React.lazy(() => lazyRetry(() => import(/* webpackChunkName: "klarnaContainer" */ './KlarnaContainer')));

const LazyComponentWithSuspense = withSuspense<LazyComponentProps>(LazyComponent, 'KlarnaContainer');

// Exports
export default LazyComponentWithSuspense;
export { LazyComponent as KlarnaContainer };
