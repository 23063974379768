// Types
import { RootState } from 'types/RootState.types';

// Config
import featureConstants from '../../constants/featureConstants';
import { isFeatureActive } from '../../redux/reducers/config/configReducer';
import { isMyJLMember } from '../myjl/isMyJLMember';

export const shouldApplyRewards = (state: RootState): boolean => {
  if (!isFeatureActive(state, featureConstants.ENABLE_CLIP_TO_CARD)) return false;

  const shouldApplyRewards = state?.orderForm?.shouldApplyRewards;
  const isMember = isMyJLMember(state?.user?.loyaltyType ?? '');

  if (!isMember || !shouldApplyRewards) return false;

  return true;
};
