/* eslint-disable no-console */
import React, { useEffect, useRef } from 'react';
import cx from 'classnames';
import ReactModal from 'react-modal';

// Design System
import { TertiaryButton } from 'jl-design-system/elements/button/Button';
import ChevronLeft24PxOutlinedIcon from 'jl-design-system/elements/icons-jb/ChevronLeft24PxOutlined';
import Close24PxOutlinedIcon from 'jl-design-system/elements/icons-jb/Close24PxOutlined';
import { setNoScroll } from 'jl-design-system/utils/scroll/noScroll';

// Types
import type { MutableRefObject, ReactElement } from 'react';
import type { ModalProps } from './Modal.types';

// Styles
import styles from './modal.module.scss';

const Modal = ({
  className,
  children,
  header,
  headerButtonAction,
  headerButtonText,
  headerChildren,
  headerCount,
  fullscreen = false,
  a11yHeader,
  forceFullHeight = false,
  closeButtonText = 'Close',
  backButtonText = 'Back',
  hideCloseButton = false,
  shouldCloseOnOverlayClick = true,
  shouldCloseOnEsc = true,
  showBackButton = false,
  large = false,
  headerStyleModifier,
  scrollId,
  disableControls = false,
  hasPadding = true,
  headerTitleClassName,
  headerButtonClassName,
  headerChildrenClassName,
  closeButtonClassName,
  modalChildrenClassName,
  dataComponent,
  isOpen = false,
  onClose = (): void => console.warn('The default implementation of props.onClose() has been called, but no action has been specified.'),
  onBack = (): void => {
    console.warn('The default implementation of props.onBack() has been called, but no action has been specified.');
  },
  downgradePageRootIdError = false,
  rootId = 'page-root',
}: ModalProps): ReactElement => {
  const isOpenRef: MutableRefObject<boolean> = useRef<boolean>(false);
  const modalOuterDivContentRef: MutableRefObject<HTMLDivElement | null> = useRef<HTMLDivElement | null>(null);

  let aria: { labelledby?: string } = {};
  let contentLabel: string | undefined;

  if (a11yHeader && a11yHeader !== header) {
    contentLabel = a11yHeader;
  } else if (header) {
    aria = {
      labelledby: 'modal-header',
    };
  } else {
    console.warn('You need to supply a "header" or "a11yHeader" prop');
  }

  const closeModal = (): void => {
    const previousIsOpen = isOpenRef.current;

    if (previousIsOpen && !disableControls) {
      setNoScroll(false);
      onClose();
    }
  };

  const onReactModalAfterOpen = (): void => {
    setNoScroll(true);
    document.documentElement.setAttribute('data-modal', 'true');
  };

  const onBackClick = (): void => {
    if (!disableControls) {
      if (modalOuterDivContentRef.current) {
        modalOuterDivContentRef.current.focus();
      }
      onBack();
    }
  };

  useEffect(() => {
    const rootElement = document?.getElementById(rootId);

    if (rootElement && rootElement !== document?.body) {
      ReactModal.setAppElement(`#${rootId}`);
    } else if (downgradePageRootIdError) {
      console.error(
        'JLDS_ERROR_01: Missing page-root ID: The main container for the page level application is missing the required id="page-root" attribute: For more info please visit => https://prod-jl-design-system-storybook.design-system.prod.jl-digital.net/?path=/docs/errors-jlds-error-01--docs',
      );
    } else {
      throw new Error(
        'JLDS_ERROR_01: Missing page-root ID: The main container for the page level application is missing the required id="page-root" attribute: For more info please visit => https://prod-jl-design-system-storybook.design-system.prod.jl-digital.net/?path=/docs/errors-jlds-error-01--docs',
      );
    }

    const prevIsOpen = isOpenRef.current;

    if (prevIsOpen && !isOpen) {
      setNoScroll(false);
    }

    isOpenRef.current = isOpen;

    return () => {
      if (document) {
        document.documentElement.setAttribute('data-modal', 'false');
      }
      setNoScroll(false);
    };
  }, [isOpen]);

  return (
    <ReactModal
      aria={aria}
      ariaHideApp
      className={{
        base: cx(className, styles.modal, {
          [styles.large]: large && !fullscreen,
          [styles.fullscreen]: fullscreen,
          [styles.forceFullHeight]: forceFullHeight,
        }),
        afterOpen: styles.modalAfterOpen,
        beforeClose: styles.modalBeforeClose,
      }}
      contentLabel={contentLabel}
      contentRef={(node: HTMLDivElement) => {
        if (modalOuterDivContentRef) {
          modalOuterDivContentRef.current = node;
        }
      }}
      data={{ jlds: 'true', component: dataComponent }}
      isOpen={isOpen}
      onAfterOpen={onReactModalAfterOpen}
      onRequestClose={() => closeModal()}
      overlayClassName={{
        base: styles.overlay,
        afterOpen: styles.overlayAfterOpen,
        beforeClose: styles.overlayBeforeClose,
      }}
      role="dialog"
      shouldCloseOnEsc={shouldCloseOnEsc}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
    >
      {(header || showBackButton) && (
        <header
          className={cx(styles.header, headerStyleModifier, {
            [styles.fullWidthHeaderText]: hideCloseButton && !showBackButton,
          })}
        >
          <h2
            className={cx(styles.headerTitle, headerTitleClassName)}
            id="modal-header"
          >
            {header}

            {typeof headerCount !== 'undefined' && (
              <span className={cx(styles.headerCount)} data-test="modal-header-count">
                {headerCount}
              </span>
            )}
            {headerButtonText && headerButtonAction && (
              <TertiaryButton
                className={cx(
                  styles.headerButton,
                  headerButtonClassName,
                )}
                data-test="modal-button-header"
                onClick={headerButtonAction}
                small
              >
                <span className={cx(styles.headerButtonText)}>
                  {headerButtonText}
                </span>
              </TertiaryButton>
            )}
          </h2>

          {headerChildren && (
            <div
              className={cx(
                styles.headerChildren,
                headerChildrenClassName,
              )}
              data-test="modal-header-children"
            >
              {headerChildren}
            </div>
          )}
        </header>
      )}

      <div
        aria-live="polite"
        className={styles.content}
        data-scroll-container={scrollId}
      >
        <div
          className={cx(styles.children, modalChildrenClassName, {
            [styles.noHeaderCloseButton]:
              !header && !hideCloseButton && !showBackButton,
            [styles['children--has-padding']]: hasPadding,
          })}
          data-scroll-content={scrollId}
          data-test="modal-children"
        >
          {children}
        </div>
      </div>

      {showBackButton && (
        <button
          className={cx(styles.backButton, styles.button)}
          data-test="modal-button-back"
          disabled={disableControls}
          onClick={onBackClick}
          type="button"
        >
          <ChevronLeft24PxOutlinedIcon />
          <span className={styles.buttonText}>{backButtonText}</span>
        </button>
      )}

      {!hideCloseButton && (
        <button
          className={cx(
            styles.button,
            styles.closeButton,
            closeButtonClassName,
          )}
          data-test="modal-button-close"
          disabled={disableControls}
          onClick={() => closeModal()}
          type="button"
        >
          <Close24PxOutlinedIcon />
          <span className={styles.buttonText}>{closeButtonText}</span>
        </button>
      )}
    </ReactModal>
  );
};

export const ModalButtons = ({
  children,
  inline,
}: {
  children: ReactElement;
  inline: boolean;
}): ReactElement => {
  if (children) {
    React.Children.forEach(children, (child) => {
      if (child?.props?.inline) {
        console.warn(
          'ModalButtons has "inline" prop which you should use instead of applying it to the Buttons.',
        );
      }
    });
  }

  return (
    <div
      className={cx(styles.actions, {
        [styles['actions--inline']]: inline,
      })}
    >
      {children}
    </div>
  );
};

export default Modal;
