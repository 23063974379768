/* eslint-disable css-modules/no-unused-class */
// Components
import GiftReceipt from '../../../components/gift-receipt';

// Styles
import styles from '../../../components/gift-message/gift-message.scss';

const GIFT_RECEIPT_CHECKBOX = {
  component: GiftReceipt,
  type: 'checkbox',
  id: 'giftReceipt',
  name: 'giftReceipt',
  label: 'I would like to include a gift message',
  labelClassName: styles.giftMessageCheckboxLabel,
  checkedValue: 'true',
};

export default GIFT_RECEIPT_CHECKBOX;
