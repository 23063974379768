import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Design System
import printLogo from 'jl-design-system/assets/logos-jb/john-lewis-_-partners-jb/john-lewis-_-partners-dark.svg';
import TickInCircle32PxOutlined from 'jl-design-system/elements/icons-jb/TickInCircle32PxOutlined';
import Link from 'jl-design-system/elements/link/Link';

// Types
import { AppDispatch } from 'types/RootState.types';

// Config
import headerState from './Header.state';
import routeConstants, { accountCreationRoutes } from '../../constants/routeConstants';
import { openLeaveCheckoutModal } from '../../redux/actions/app/appActions';
import returnToJL from '../../utils/helpers/returnToJL';

// Components
import Container from '../container';
import Heading from '../heading';

// Styles
import styles from './header.scss';

const Header = () => {
  const dispatch: AppDispatch = useDispatch();
  const { isLoading, order, orderComplete, pathname = './', showFullScreenSignUpFlow } = useSelector(headerState);

  const onLeaveCheckout = () => dispatch(openLeaveCheckoutModal(pathname));

  const HeaderLogoLink = () => {
    const showLeaveCheckoutModal =
      pathname.includes(routeConstants.DELIVERY) ||
      pathname.includes(routeConstants.CLICK_AND_COLLECT) ||
      pathname.includes(routeConstants.PAYMENT);
    const Tag = showLeaveCheckoutModal ? 'button' : Link;

    const commonProps = {
      className: styles.headerLogoLink,
      'data-test': 'header-logo-link',
    };

    const tagSpecificProps = showLeaveCheckoutModal
      ? { onClick: onLeaveCheckout, type: 'button' }
      : { external: true, to: returnToJL() };

    return (
      <Container hideForPrint>
        <Tag {...commonProps} {...tagSpecificProps}>
          <span className={styles.visuallyHiddenText}>John Lewis</span>
        </Tag>
      </Container>
    );
  };

  const isPathForAccountCreation = accountCreationRoutes.some(routeConstant => pathname.includes(routeConstant));

  const showAccountCreatedHeader = isPathForAccountCreation
    && !isLoading
    && orderComplete
    && showFullScreenSignUpFlow;

  return (
    <>
      <header
        className={styles.header}
        data-test="app-header"
      >
        <div className={styles.container}>
          <img
            alt="John Lewis & Partners"
            className={styles.printLogo}
            src={printLogo}
          />

          <HeaderLogoLink />

          <Container
            className={styles.secureCheckout}
            hideForPrint
            testId="header-secure-message"
          >
            <span
              className={styles.smallSecureText}
              data-test="small-secure-text"
            >
              <span className={styles.smallSecureIcon} />
              Secure
            </span>
            <span
              className={styles.largeSecureText}
              data-test="large-secure-text"
            >
              Secure checkout
              <span className={styles.largeSecureIcon} />
            </span>
          </Container>
          <p className={styles.printOrderId} data-test="header-order-id">
            Order number: <span><strong>{order?.id}</strong></span>
          </p>
        </div>
      </header>

      {showAccountCreatedHeader && (
        <Container borderBottom="grey-15" paddingY="3" textAlign="center">
          <Heading className={styles.signUpHeading} type="xs" uppercase>
            <TickInCircle32PxOutlined />
            <span>Account created</span>
          </Heading>
        </Container>
      )}
    </>
  );
};

export default Header;
