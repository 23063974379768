// Types
import { ProductProps } from 'types/Product.types';
import { RootState } from 'types/RootState.types';

// Config
import featureConstants from '../../constants/featureConstants';
import { isFeatureActive } from '../../redux/reducers/config/configReducer';

export const APPLE_SKU_IDS = [
  '112608757', // Apple iPhone 16, iOS, 6.1", 5G, SIM Free, 256GB
  '112608762', // Apple iPhone 16, iOS, 6.1", 5G, SIM Free, 512GB
  '112608767', // Apple iPhone 16, iOS, 6.1", 5G, SIM Free, 128GB
  '112608771', // Apple iPhone 16 Plus, iOS, 6.7", 5G, SIM Free, 256GB
  '112608776', // Apple iPhone 16 Plus, iOS, 6.7", 5G, SIM Free, 512GB
  '112608781', // Apple iPhone 16 Plus, iOS, 6.7", 5G, SIM Free, 128GB
  '112608785', // Apple iPhone 16 Pro, iOS, 6.3", 5G, SIM Free, 1TB
  '112608789', // Apple iPhone 16 Pro, iOS, 6.3", 5G, SIM Free, 512GB
  '112608793', // Apple iPhone 16 Pro, iOS, 6.3", 5G, SIM Free, 128GB
  '112608796', // Apple iPhone 16 Pro, iOS, 6.3", 5G, SIM Free, 256GB
  '112608800', // Apple iPhone 16 Pro Max, iOS, 6.9", 5G, SIM Free, 512GB
  '112608811', // Apple iPhone 16 Pro Max, iOS, 6.9", 5G, SIM Free, 1TB
  '112608819', // Apple iPhone 16 Pro Max, iOS, 6.9", 5G, SIM Free, 256GB
  '112608973', // Apple Watch Series 10 GPS + Cellular, 46mm, Aluminium Case, Sport Loop, One Size
  '112608980', // Apple Watch Series 10 GPS + Cellular 46mm Natural Titanium Case with Sport Band
  '112608985', // Apple Watch Series 10 GPS + Cellular, 46mm, Titanium Case, Milanese Loop
  '112608954', // Apple Watch Series 10 GPS + Cellular 42mm Sport Band
  '112608962', // Apple Watch Series 10 GPS + Cellular 42mm  Aluminium Case with Sport Loop
  '112608968', // Apple Watch Series 10 GPS + Cellular, 46mm, Aluminium Case, Sport Band
  '112608986', // Apple Watch Series 10 GPS + Cellular 42mm Titanium Case with Milanese Loop
  '112608966', // Apple Watch Series 10 GPS + Cellular, 42mm, Titanium Case, Sport Band
  '112608947', // Apple Watch Series 10 GPS 46mm Sport Loop
  '112608936', // Apple Watch Series 10 GPS, 42mm, Aluminium Case, Sport Band
  '112608938', // Apple Watch Series 10 GPS, 42mm, Aluminium Case, Sport Loop, One Size
  '112608945', // Apple Watch Series 10 GPS, 46mm, Aluminium Case, Sport Band
  '112608919', // Apple Watch Ultra 2 GPS + Cellular 49mm Black Titanium Case with Dark Green Alpine Loop
  '112608911', // Apple Watch Ultra 2 GPS + Cellular, 49mm, Titanium Case with Alpine Loop, Small, Natural/Tan
  '112608915', // Apple Watch Ultra 2 GPS + Cellular, 49mm, Titanium Case with Trail Loop, Natural/Blue
  '112608920', // Apple Watch Ultra 2 GPS + Cellular, 49mm, Titanium Case with Trail Loop, Black/Black
  '112608922', // Apple Watch Ultra 2 GPS + Cellular, 49mm, Titanium Case with Ocean Band, One Size
];

export const shouldShowAppleRecommendations = (state: RootState) => {
  if (!isFeatureActive(state, featureConstants.APPLE_RECOMMENDATIONS)) return false;

  if (!isFeatureActive(state, featureConstants.SHOW_RECOMMENDATIONS)) return false;

  const targetSkuIDs = new Set(APPLE_SKU_IDS);

  const items = state?.confirmation?.order?.items ?? [];

  return items.some((item: ProductProps) => item.skuId && targetSkuIDs.has(item.skuId));
};
