import React, { useMemo } from 'react';
import cx from 'classnames';

// Types
import { BodyTextThemeProps } from './BodyText.types';

// Config
import { textThemes, getSpacingClass } from '../design-system/DesignSystem.constants';
import { getDataAttributes } from '../../utils/helpers/dataAttributes';

const BodyText = ({
  children,
  className,
  marginBottom = '0',
  maskContentSquare = false,
  maskPercy = false,
  maskText = false,
  noTheme = false,
  tag: Tag = 'p',
  testId,
  type = 'm',
  ...otherProps
}: BodyTextThemeProps & React.HTMLAttributes<HTMLElement>) => {
  const themeClass = useMemo(() => !noTheme && (textThemes[type] || textThemes.m), [noTheme, type]);
  const marginBottomClass = useMemo(
    () => getSpacingClass({ type: 'margin', position: 'bottom', size: marginBottom, backupSize: '0' }),
    [marginBottom],
  );

  const bodyTextClass = useMemo(
    () => cx(themeClass, marginBottomClass, className), [themeClass, marginBottomClass, className],
  );

  const memoTag = useMemo(() => children && (
    <Tag
      className={bodyTextClass || undefined}
      {...getDataAttributes({ maskContentSquare, maskPercy, maskText, testId })}
      {...otherProps}
    >
      {children}
    </Tag>
  ), [Tag, bodyTextClass, children, maskContentSquare, maskPercy, maskText, testId, otherProps]);

  return memoTag;
};

export default BodyText;
