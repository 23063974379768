import { createSelector } from 'reselect';

// Types
import { RootState } from 'types/RootState.types';
import { ProductProps } from 'types/Product.types';

// Config
import { shouldShowAgeVerificationCheck, shouldShowAgeVerificationCheckForAllItems } from '../../redux/actions/age-verification/ageVerificationActions';
import { getItemsNeedingAgeVerificationSelector } from '../../redux/reducers/delivery/deliverySelector';
import {
  getAgeRestrictedProductsInBasketSelector,
  getIsSignedInSelector,
  getShowAgeCheckModalSelector,
  getShowYotiConfirmationModalSelector,
  getYotiCheckSuccessSelector,
  getYotiSessionIdSelector,
} from '../../redux/reducers/user/userSelector';
import { orderFormContainsBladedItemsOnlySelector, getOrderFormItemsSelector } from '../../redux/reducers/order-form/orderFormSelector';
import getItemQuantityById from '../../utils/orderform/getQuantityById';

const getAgeVerificationFeatureActiveSelector = (state: RootState) => shouldShowAgeVerificationCheck(state);
const getAgeVerificationFeatureAllItemsActiveSelector =
  (state: RootState) => shouldShowAgeVerificationCheckForAllItems(state);

export const ageVerificationProductsSelector = createSelector(
  [
    getAgeVerificationFeatureActiveSelector,
    getAgeVerificationFeatureAllItemsActiveSelector,
    getYotiCheckSuccessSelector,
    getOrderFormItemsSelector,
    getItemsNeedingAgeVerificationSelector,
    getAgeRestrictedProductsInBasketSelector,
  ],
  (
    featureYotiActive,
    featureYotiAllItemsActive,
    yotiCheckSuccess,
    orderFormItems = [],
    itemsNeedingVerification = [],
    restrictedProducts = [],
  ) => {
    if (featureYotiAllItemsActive) {
      if (yotiCheckSuccess || itemsNeedingVerification?.length === 0) return [];

      return restrictedProducts?.reduce((acc: ProductProps[], product: ProductProps) => {
        if (product?.bladedArticle) {
          acc.push({
            ...product,
            quantity: getItemQuantityById(product.id, orderFormItems),
          });
        }
        return acc;
      }, []);
    }
    return featureYotiActive ? itemsNeedingVerification : undefined;
  },
);

const getShouldShowAgeCheckModalSelector = createSelector(
  [
    getAgeVerificationFeatureAllItemsActiveSelector,
    getShowAgeCheckModalSelector,
    getItemsNeedingAgeVerificationSelector,
  ],
  (
    featureYotiAllItemsActive,
    showModal,
    itemsNeedingVerification = [],
  ) => showModal && (
    (featureYotiAllItemsActive && itemsNeedingVerification?.length === 0) || !featureYotiAllItemsActive),
);

const ageCheckerState = createSelector(
  [
    ageVerificationProductsSelector,
    orderFormContainsBladedItemsOnlySelector,
    getIsSignedInSelector,
    getShouldShowAgeCheckModalSelector,
    getYotiSessionIdSelector,
    getShowYotiConfirmationModalSelector,
    getItemsNeedingAgeVerificationSelector,
  ],
  (
    ageVerificationProducts,
    bladedItemsOnly,
    isSignedIn,
    showAgeCheckModal,
    yotiSessionId,
    showYotiConfirmationModal,
    itemsNeedingVerification = [],
  ) => ({
    ageVerificationProducts,
    bladedItemsOnly,
    isSignedIn,
    showAgeCheckModal,
    yotiSessionId,
    showYotiConfirmationModal,
    itemsNeedingVerification,
  }),
);

export default ageCheckerState;
