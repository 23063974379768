// Types
import { RootState } from 'types/RootState.types';

// Config
import featureConstants from '../../constants/featureConstants';
import { isFeatureActive } from '../../redux/reducers/config/configReducer';
import { orderFormBasketContainsAlcoholSelector } from '../../redux/reducers/order-form/orderFormSelector';

export const isBasketKlarnaEligible = (state: RootState): boolean => {
  const deliveriesWithClearabee = state?.delivery?.deliveriesWithClearabee ?? [];
  const outstandingBalanceRaw = Number(state?.orderForm?.amounts?.outstandingBalanceRaw);
  const basketContainsAlcohol = orderFormBasketContainsAlcoholSelector(state);

  if (
    basketContainsAlcohol
    || deliveriesWithClearabee?.length > 0
    || (outstandingBalanceRaw < 30 || outstandingBalanceRaw > 2000)
  ) {
    return false;
  }

  return true;
};

export const shouldShowKlarnaFeature =
  (state: RootState): boolean => isFeatureActive(state, featureConstants.ENABLE_KLARNA);

export const shouldShowKlarna =
  (state: RootState): boolean => shouldShowKlarnaFeature(state) && isBasketKlarnaEligible(state);
