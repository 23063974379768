export default {
  APPLE_PAY: 'applePay',
  APPLE_PAY_EXPRESS: 'applePayExpress',
  CLEARPAY: 'clearpay',
  CREDIT_CARD: 'creditCard',
  GIFT_CARD: 'giftCard',
  GIFT_OPTIONS: 'giftOptions',
  GIFT_VOUCHER: 'giftVoucher',
  GOOGLE_PAY: 'googlePay',
  KLARNA: 'klarna',
  OLD_PARTNERSHIP_BIN_RANGE: '542011',
  PAYPAL: 'payPal',
  PAYPAL_PAY_LATER: 'payPalPayLater',
  PAYPAL_PAY_EXPRESS: 'payPalPayExpress',
  POS_CREDIT: 'posCredit',
  POS_CREDIT_PAYMENT_HOLIDAY: 'PAYMENT_HOLIDAY',
  PREPAID_CARD: 'prepaidCard',
  PROMO_CODE: 'promoCode',
  SAVED_PAYMENT_CARD: 'savedPaymentCard',
  TOKENIZED_PAYMENT_CARD: 'tokenizedPaymentCard',
};
