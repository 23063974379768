function thumbnail(src: string) {
  if (!src) return null;

  const imageArgs = '$rsp-bskt-thmb$';

  if (src.charAt(src.length - 1) === '?') {
    return `${src}${imageArgs}`;
  }

  if (src.includes('fmt=auto')) {
    return `${src}&${imageArgs}`;
  }

  return `${src}?${imageArgs}`;
}

export default thumbnail;
