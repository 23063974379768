import { push, replace } from 'connected-react-router';
import {
  setSubmittingTrue,
  setSubmittingFalse,
  submitForm,
  touchForm,
  resetForm,
} from 'jl-design-system/redux/actions/form/formActions';
import env from 'jl-design-system/utils/env/env';
import validate from 'jl-design-system/form/validation/validate';
import { ADDRESS_SEARCH_FORM_ID } from 'jl-design-system/form/configs/addressSearch';
import { ADDRESS_SEARCH_INPUT_ID } from 'jl-design-system/form/fields/addressSearchInput';
//
import { setCutOffPassedModalVisibility } from '../click-and-collect/clickAndCollectActions';
import { submitAndHandleCardPayment } from './cardPaymentActions';
import { submitOrderAndRedirect, resetSubmitOrderError } from './submitOrderActions';
import { triggerAnalyticsEvent } from '../analytics/analyticsAction';
import {
  isValid,
  processForms,
  handleSubmitFormValidationErrorAnalytics,
  resetAllOtherForms,
} from '../form/formActions';
import { putUpdatedAddress } from '../addressBook/addressBookActions';
//
import errorCodeConstants from '../../../constants/errorCodeConstants';
import { requiredActionTypes } from '../../../constants/posCreditConstants';
import {
  URL_CUSTOMER_PAYMENT_CARD_DELETE,
  URL_PAYMENTS,
  URL_GIFT_TOKENS,
} from '../../../constants/endpointConstants';
import getBillingDetailsFormConfig, {
  BILLING_ADDRESS_FORM_ID,
  RESIDENTIAL_ADDRESS_FORM_ID,
} from '../../../utils/form/configs/billingAddress';
import { CARD_DETAILS_FORM_ID, PREPAID_CARD_DETAILS_FORM_ID } from '../../../utils/form/configs/cardDetails';
import { SAVED_CARD_DETAILS_FORM_ID } from '../../../utils/form/configs/savedCardDetails';
import { PROMO_CODE_FORM_ID, PROMO_CODE } from '../../../utils/form/configs/promoCode';
import paymentTypeConstants from '../../../constants/paymentTypeConstants';
import routeConstants from '../../../constants/routeConstants';
import {
  getRegisteredFormFieldNames,
  isFormMounted,
  isZeroAmount,
  loadScript,
  scrollToElement,
} from '../../../utils';
import { getBillingAddress } from '../../../utils/address/addressHelpers';
import logUnsupportedCardType from '../../../utils/payment/logUnsupportedCardType';
import isSavedPaymentCard from '../../../utils/payment/isSavedPaymentCard';

import {
  ANALYTICS_PAYMENT_CARD_DELETE_OPEN,
  ANALYTICS_PAYMENT_OPTION_SELECTED,
  ANALYTICS_PLACE_ORDER_AND_PAY_CLICK,
  ANALYTICS_PLACE_ORDER_AND_PAY_CLICK_START,
  CARD_FORM_VALIDATION_STATUS,
  CARD_PAYMENT,
  CARD_TYPE_MISSING,
  CHANGE_PAYMENT_DETAILS,
  CLOSE_POS_CREDIT_GET_READY_MODAL,
  GIFT_OPTIONS_CHANGE_OPTION,
  GIFT_OPTIONS_MODAL_CLOSE,
  GIFT_OPTIONS_MODAL_OPEN,
  HIDE_POS_CREDIT_BASKET_AMEND_MODAL,
  MOUNT_POS_CREDIT_ADDRESS_FORM,
  PAYMENT_CARD_DELETE,
  SET_PAYMENT_PROCESSING,
  SET_PAYMENT_TYPE,
  SET_SELECTED_SAVED_CARD,
  SHOW_POS_CREDIT_BASKET_AMEND_MODAL,
  SHOW_POS_CREDIT_GET_READY_MODAL,
  SHOW_POS_CREDIT_PROVIDER_UNAVAILABLE_ERROR,
  TRANSACTION_STATUS_UPDATE,
  IOVATION_SCRIPTS_FAILED,
  IOVATION_SCRIPTS_LOADED,
  IOVATION_SCRIPTS_LOADING,
  GET_PAYMENTS,
  POST_PAYMENTS,
  GET_GIFTING_PAYMENT_DETAILS,
} from '../../../constants/actionConstants';
import deliveryConstants from '../../../constants/deliveryConstants';
import { applePayPaymentInit } from './applePayActions';
import { fetchPaymentWallet } from '../user/userActions';
import { SAVE_AS_DEFAULT_FORM_ID } from '../../../utils/form/configs/saveAsDefault';
import determineSelectedPaymentType from './selectedPaymentTypeActions';

export const showPaymentCardDeleteModal = () => (dispatch) => {
  dispatch(triggerAnalyticsEvent(ANALYTICS_PAYMENT_CARD_DELETE_OPEN));
  dispatch(push(routeConstants.PAYMENT_CARD_DELETE));
};

export const deletePaymentCard = (cardId, last4Digits) => async (dispatch, getState) => {
  const isPartnerDiscountEnabled = getState().user?.partnerDiscountEnabled ?? false;

  const response = await dispatch({
    type: PAYMENT_CARD_DELETE,
    request: client => client({
      path: URL_CUSTOMER_PAYMENT_CARD_DELETE(cardId),
      config: {
        method: 'DELETE',
      },
    }),
    isPartnerDiscountEnabled,
    last4Digits,
  });

  const errorCode = response?.error?.code;
  if (errorCode === errorCodeConstants.CLIENT_PAYMENT_CARD_NOT_DELETED) {
    await dispatch(fetchPaymentWallet());
  }

  dispatch(determineSelectedPaymentType());
  dispatch(replace(routeConstants.PAYMENT));
};

export const showBillingAddressBookModal = () => (dispatch) => {
  dispatch(push(routeConstants.PAYMENT_ADDRESS_BOOK));
};

export const setSelectedPaymentType = ({
  paymentType = '',
  savedPaymentCards = [],
  shouldSetBillingAddress = true,
  posCreditType = '',
} = {}) => async (dispatch, getState) => {
  await dispatch({
    type: SET_PAYMENT_TYPE,
    paymentType,
    savedPaymentCards,
    shouldSetBillingAddress,
    posCreditType,
  });

  const payOptionsAnalytics = getState()?.payment?.payOptionsAnalytics ?? {};
  return dispatch(triggerAnalyticsEvent(ANALYTICS_PAYMENT_OPTION_SELECTED, {
    payOptionsAnalytics,
  }));
};

export const openGiftOptionsModal = () => async (dispatch) => {
  await dispatch({
    type: GIFT_OPTIONS_MODAL_OPEN,
  });

  dispatch(push(routeConstants.PAYMENT_GIFT_OPTIONS));
};

export const closeGiftOptionsModal = () => async (dispatch) => {
  await dispatch({
    type: GIFT_OPTIONS_MODAL_CLOSE,
  });

  dispatch(push(routeConstants.PAYMENT));
};

export const changeGiftOption = contentId => ({
  type: GIFT_OPTIONS_CHANGE_OPTION,
  contentId,
});

export const setPaymentProcessing = value => ({
  type: `${SET_PAYMENT_PROCESSING}.${value ? 'TRUE' : 'FALSE'}`,
});

export const setCardFormValidationStatus = isCardFormValid => ({
  type: CARD_FORM_VALIDATION_STATUS,
  isCardFormValid,
});

export const changePaymentDetails = () => ({
  type: CHANGE_PAYMENT_DETAILS,
});

export const triggerPlaceOrderAndPayStartAnalytics = paymentType => ({
  type: ANALYTICS_PLACE_ORDER_AND_PAY_CLICK_START,
  paymentType,
});

export const triggerPlaceOrderAndPayAnalytics = paymentId => async (dispatch, getState) => {
  // TODO dispatching with promocode can be removed after https://www.jlpit.com/jira/browse/MARV-5940 has been determined
  const promoCodeText = getState().form?.[PROMO_CODE_FORM_ID]?.values?.[PROMO_CODE.id];
  const dispatchValues = {
    type: ANALYTICS_PLACE_ORDER_AND_PAY_CLICK,
    paymentId,
  };

  if (promoCodeText) {
    dispatch({
      ...dispatchValues,
      promoCodeText,
    });
  } else {
    dispatch(dispatchValues);
  }
};

export const triggerTransactionStatusAnalytics = (transactionStatus, args) => ({
  type: TRANSACTION_STATUS_UPDATE,
  transactionStatus,
  ...args,
});

export const pendingBrowserCheck = (id, actionUrl, formData) => ({
  type: `${CARD_PAYMENT}.SUCCESS`,
  result: {
    ocpPaymentId: id,
    threeDSMethodInfo: {
      threeDSMethodRequired: true,
      threeDSMethodFormId: '3DSMethodForm',
      threeDSMethodUrl: actionUrl,
      threeDSMethodDataFormData: formData,
    },
  },
});

export const pendingChallege = (actionUrl, formData) => ({
  type: `${CARD_PAYMENT}.SUCCESS`,
  result: {
    creditCard3DSecureInfo: {
      is3DSecureAvailable: true,
      creditCard3DSecureFormId: '3DSForm',
      creditCard3DSecureMerchantUrl: actionUrl,
      creditCard3DSecureFormData: formData,
    },
  },
});

export const handleInvalidSavedBillingAddress = ({ billingAddress = {} }) => (dispatch, getState) => {
  const {
    user: {
      isSignedInWithData,
      addressBook = [],
    } = {},
  } = getState();

  if (isSignedInWithData) {
    const billingAddressInAddressBook = addressBook?.find(addressRecord => addressRecord.id === billingAddress.id);

    if (billingAddressInAddressBook?.invalid) {
      return dispatch(putUpdatedAddress({ body: billingAddress }));
    }
  }

  return {};
};

export const submitAndHandlePayment = ({ type = '' }) => async (dispatch, getState) => {
  const state = getState();

  const billingAddress = getBillingAddress(state);
  await dispatch(handleInvalidSavedBillingAddress({ billingAddress }));

  if (type === paymentTypeConstants.GIFT_OPTIONS) {
    await dispatch(triggerPlaceOrderAndPayAnalytics(null));
    return dispatch(submitOrderAndRedirect({ billingAddress }));
  }

  const savedPaymentCards = state?.user?.savedPaymentCards;
  const isSavedPayment = isSavedPaymentCard(type);
  const isPrepaidCard = type === paymentTypeConstants.PREPAID_CARD;

  let cardDetails;
  let cardToBeStored = false;
  const {
    addressee: {
      lastName,
      firstName,
    } = {},
  } = billingAddress;

  if (isSavedPayment) {
    const id = type.split(':')[1];
    const savedPaymentCard = savedPaymentCards?.find(paymentCard => paymentCard.id === id);
    cardDetails = {
      ...state.form?.[SAVED_CARD_DETAILS_FORM_ID]?.values,
      cardNumber: savedPaymentCard.obfuscatedPan.substr(0, 6),
      cardType: savedPaymentCard.type,
      cardId: savedPaymentCard.id,
      expiryDate: `${savedPaymentCard.expirationYear}-${savedPaymentCard.expirationMonth}`,
      cardholderName: savedPaymentCard.nameOnCard,
    };
  } else if (type === paymentTypeConstants.CLEARPAY) {
    cardDetails = {
      ...state.payment.clearpayCardDetails,
      cardholderName: `${firstName} ${lastName}`,
    };
    cardToBeStored = false;
  } else if (type === paymentTypeConstants.KLARNA) {
    cardDetails = {
      ...state.payment.klarnaCardDetails,
      cardholderName: `${firstName} ${lastName}`,
    };
    cardToBeStored = false;
  } else {
    cardDetails = isPrepaidCard ? {
      ...state.form?.[PREPAID_CARD_DETAILS_FORM_ID]?.values,
      cardholderName: `${firstName} ${lastName}`,
    } :
      state.form?.[CARD_DETAILS_FORM_ID]?.values;
    cardToBeStored = state.form?.[SAVE_AS_DEFAULT_FORM_ID]?.values?.savePaymentForFuture ?? false;
  }

  const ocpBaseUrl = state.orderForm?.ocpIntegration?.baseUrl;
  return dispatch(submitAndHandleCardPayment({
    cardDetails,
    billingAddress,
    ocpBaseUrl,
    savedCardPayment: isSavedPayment,
    cardToBeStored,
  }));
};

export const setSavedPaymentFormsSubmittingTrue = () => (dispatch) => {
  dispatch(setSubmittingTrue(BILLING_ADDRESS_FORM_ID));
  dispatch(setSubmittingTrue(SAVED_CARD_DETAILS_FORM_ID));
};

export const setPaymentFormsSubmittingTrue = () => (dispatch) => {
  dispatch(setSubmittingTrue(BILLING_ADDRESS_FORM_ID));
  dispatch(setSubmittingTrue(CARD_DETAILS_FORM_ID));
};

export const setPaymentFormsSubmittingFalse = () => (dispatch) => {
  dispatch(setSubmittingFalse(BILLING_ADDRESS_FORM_ID));
  dispatch(setSubmittingFalse(CARD_DETAILS_FORM_ID));
};

export const setSavedPaymentFormsSubmittingFalse = () => (dispatch) => {
  dispatch(setSubmittingFalse(BILLING_ADDRESS_FORM_ID));
  dispatch(setSubmittingFalse(SAVED_CARD_DETAILS_FORM_ID));
};

export const setPrepaidPaymentFormsSubmittingTrue = () => (dispatch) => {
  dispatch(setSubmittingTrue(BILLING_ADDRESS_FORM_ID));
  dispatch(setSubmittingTrue(PREPAID_CARD_DETAILS_FORM_ID));
};

export const setPrepaidPaymentFormsSubmittingFalse = () => (dispatch) => {
  dispatch(setSubmittingFalse(BILLING_ADDRESS_FORM_ID));
  dispatch(setSubmittingFalse(PREPAID_CARD_DETAILS_FORM_ID));
};

export const clearPaymentPageErrors = (ignoreIds, keepSyncErrors = false) => async (dispatch) => {
  await dispatch(resetSubmitOrderError());
  if (ignoreIds) {
    await dispatch(resetAllOtherForms(ignoreIds, keepSyncErrors));
  }
};

const PAYMENT_FORMS = [
  CARD_DETAILS_FORM_ID,
  BILLING_ADDRESS_FORM_ID,
  SAVED_CARD_DETAILS_FORM_ID,
  RESIDENTIAL_ADDRESS_FORM_ID,
  PREPAID_CARD_DETAILS_FORM_ID,
];

const validateInvalidAddressForm = () => async (dispatch) => {

  const processFormsErrors = await dispatch(processForms(PAYMENT_FORMS));

  const formSyncErrors = {
    ...processFormsErrors,
  };

  if (!isValid(formSyncErrors)) {
    await dispatch(handleSubmitFormValidationErrorAnalytics(formSyncErrors));
    return false;
  }

  return true;
};

const validateCardDetails = (type, isSavedPayment) => async (dispatch) => {

  let formId;

  if (isSavedPayment) {
    formId = SAVED_CARD_DETAILS_FORM_ID;
  } else if (type === paymentTypeConstants.PREPAID_CARD) {
    formId = PREPAID_CARD_DETAILS_FORM_ID;
  } else {
    formId = CARD_DETAILS_FORM_ID;
  }

  const formSyncErrors = await dispatch(processForms(formId, PAYMENT_FORMS));

  if (!isValid(formSyncErrors)) {
    await dispatch(handleSubmitFormValidationErrorAnalytics(formSyncErrors));
    return false;
  }

  return true;
};

export const validateBillingAddress = () => async (dispatch, getState) => {
  const selectedPaymentType = getState().payment?.selectedPaymentType;
  const form = (selectedPaymentType?.includes(paymentTypeConstants.POS_CREDIT) || false) ?
    RESIDENTIAL_ADDRESS_FORM_ID :
    BILLING_ADDRESS_FORM_ID;

  const processFormsErrors = await dispatch(processForms(form, PAYMENT_FORMS));

  const formSyncErrors = {
    ...processFormsErrors,
  };

  if (!isValid(formSyncErrors)) {
    await dispatch(handleSubmitFormValidationErrorAnalytics(formSyncErrors));
    return false;
  }

  return true;
};

export const validatePostcodeSearch = () => async (dispatch, getState) => {
  const searchFormId = ADDRESS_SEARCH_FORM_ID;
  const searchForm = getState().form?.[searchFormId];

  if (isFormMounted(searchForm)) {

    const formSyncErrors = {};
    let loqateFieldOriginalValue;

    if (getState().form?.[searchFormId]?.values) {
      // MARV-6044 hack to provoke validation when:
      // a) customer has entered text in Loqate field and
      // b) has NOT selected an address

      // preserve the text the customer has enter
      loqateFieldOriginalValue = getState().form?.[searchFormId]?.values?.[ADDRESS_SEARCH_INPUT_ID];

      // reset the loqate form so it will be empty and invalid during submission
      dispatch(resetForm(searchFormId));
    }

    await dispatch(clearPaymentPageErrors([searchFormId]), false);
    // this will error in two scenarios
    // 1. if search fields is empty or invalid (syncError)
    // 2. if search field contains a valid postcode (via SubmissionError)
    // the user should always be prompted to complete the address search
    // before attempting to pay

    // ensure we have syncErrors
    const fieldNames = getRegisteredFormFieldNames(searchFormId, true);
    await dispatch(touchForm(searchFormId, fieldNames));
    // submit to trigger error
    dispatch(submitForm(searchFormId));
    const syncErrors = getState().form?.[searchFormId]?.syncErrors ?? {};

    formSyncErrors[searchFormId] = syncErrors;

    if (loqateFieldOriginalValue) {
      // MARV-6044 Hack part 2
      const loqateInput = document.querySelector(`input[name="${ADDRESS_SEARCH_INPUT_ID}"]`);

      if (loqateInput) {
        // Reinsert the customer's text into the loqate input WITHOUT triggering a change event
        // Can't use setFieldValue util as the change event causes validation error to vanish
        loqateInput.value = loqateFieldOriginalValue;

        // Focus in the input field to cause the address dropdown to appear
        // Timeout is necessary otherwise error message gains focus instead
        setTimeout(() => {
          loqateInput.focus();
        }, 0);
      }
    }

    if (!isValid(formSyncErrors)) {
      await dispatch(handleSubmitFormValidationErrorAnalytics(formSyncErrors));
      return false;
    }
  }

  return true;
};

export const validator = {
  validateInvalidAddressForm,
  validateBillingAddress,
  validateCardDetails,
  validatePostcodeSearch,
};

export const validateForms = {
  validateAll: (
    dispatch,
    deliveries,
    type,
    isDelivery,
    isClickAndCollect,
    isSavedPayment,
  ) => (
    new Promise(async (resolve) => {
      if (
        type === paymentTypeConstants.CREDIT_CARD ||
        type === paymentTypeConstants.PREPAID_CARD ||
        isSavedPaymentCard(type) ||
        type === paymentTypeConstants.TOKENIZED_PAYMENT_CARD
      ) {
        const cardDetailsValid = await dispatch(validator.validateCardDetails(type, isSavedPayment));
        if (!cardDetailsValid) {
          return resolve(false);
        }
      }
      const invalidAddressFormValid = await dispatch(validator.validateInvalidAddressForm());
      if (!invalidAddressFormValid) {
        return resolve(false);
      }

      const billingDetailsValid = await dispatch(validator.validateBillingAddress(type));
      if (!billingDetailsValid) {
        return resolve(false);
      }

      const postcodeSearchValid = await dispatch(validator.validatePostcodeSearch());
      if (!postcodeSearchValid) {
        return resolve(false);
      }

      return resolve(true);
    })
  ),
};

export const closePOSGetReadyModal = () => ({
  type: CLOSE_POS_CREDIT_GET_READY_MODAL,
});

export const showPOSGetReadyModal = () => ({
  type: SHOW_POS_CREDIT_GET_READY_MODAL,
});

export const showPosCreditProviderUnavailableError = () => ({
  type: SHOW_POS_CREDIT_PROVIDER_UNAVAILABLE_ERROR,
});

export const showPOSCreditBasketAmendModal = creditTypeGroup => ({
  type: SHOW_POS_CREDIT_BASKET_AMEND_MODAL,
  basketAmendCreditTypeGroup: creditTypeGroup,
});

export const hidePOSCreditBasketAmendModal = () => ({
  type: HIDE_POS_CREDIT_BASKET_AMEND_MODAL,
});

export const validateSavedPosCreditAddress = () => async (dispatch, getState) => {
  const {
    form,
    user,
  } = getState();

  const residentialAddressForm = form?.[RESIDENTIAL_ADDRESS_FORM_ID];

  if (!isFormMounted(residentialAddressForm)) {
    const address = user.selectedResidentialAddress || {};
    const result = validate({
      ...address.address,
      ...address.addressee,
      phoneNumber: address.phoneNumber,
    }, { config: getBillingDetailsFormConfig({
      actions: {},
      countryCode: address.address?.countryCode,
      configExtras: {
        isPosCredit: true,
      },
      enableGBCounty: !!address.address?.countyStateOrProvince,
    }) });

    // the pos credit billing address is invalid by pos-credit standards
    // so mount the form to display validation errors caught in validateAll
    if (Object.keys(result).length) {
      dispatch({ type: MOUNT_POS_CREDIT_ADDRESS_FORM });
    }
  }
};

/**
 * Initiates submission of form data to the required payment APIs
 * If validation errors are present in the forms -> dispatches analytics error event and APIs are NOT called
 * If NO validation errors are present in the forms -> calls payment APIs and submits the order
 */
export const submitPayment = () => async (dispatch, getState) => {
  const state = getState();
  const cutOffPassed = state.clickAndCollect?.cutOffPassed;
  const showCutOffPassedModal = state.clickAndCollect?.showCutOffPassedModal;
  const selectedPaymentType = state.payment?.selectedPaymentType;
  const isPosCredit = (selectedPaymentType?.includes(paymentTypeConstants.POS_CREDIT) || false);
  const isZeroOutstandingBalance = isZeroAmount(getState().orderForm?.amounts?.outstandingBalance);

  const shouldRunPOSCBasketChecks = isPosCredit && !isZeroOutstandingBalance;
  if (shouldRunPOSCBasketChecks) {
    const posCreditProviderUnavailable = getState().orderForm?.posCreditProviderUnavailable;
    const creditTypeGroupId = selectedPaymentType.split(':')[1];
    const creditTypeGroups = state.payment?.creditTypeGroups ?? [];
    const creditTypeGroup = creditTypeGroups?.find(group => group.id === creditTypeGroupId);
    const requiredActions = creditTypeGroup?.requiredActions ?? [];
    const requiresBasketAmendment = requiredActions.some(action => action.type === requiredActionTypes.REMOVE_ITEMS);

    if (posCreditProviderUnavailable) {
      dispatch(showPosCreditProviderUnavailableError());
      return;
    }

    if (requiresBasketAmendment) {
      dispatch(showPOSCreditBasketAmendModal(creditTypeGroup));
      return;
    }

    dispatch(validateSavedPosCreditAddress());
  }

  if (cutOffPassed && !showCutOffPassedModal) {
    dispatch(setCutOffPassedModalVisibility(true));
  } else {
    const selectedDeliveryChoiceId = getState().delivery?.selectedDeliveryChoiceId;
    const isDelivery = selectedDeliveryChoiceId === deliveryConstants.DELIVERY;
    const isClickAndCollect = selectedDeliveryChoiceId === deliveryConstants.CLICK_AND_COLLECT;

    if (isClickAndCollect) {
      dispatch(setCutOffPassedModalVisibility(false));
    }

    const deliveries = getState().delivery?.deliveries ?? [];
    const type = isZeroOutstandingBalance ? paymentTypeConstants.GIFT_OPTIONS : selectedPaymentType;

    const isSavedPayment = isSavedPaymentCard(type);

    dispatch(setPaymentProcessing(true));

    const validateFormsResult = await validateForms.validateAll(
      dispatch,
      deliveries,
      type,
      isDelivery,
      isClickAndCollect,
      isSavedPayment,
    );

    if (validateFormsResult && type.includes(paymentTypeConstants.POS_CREDIT)) {
      dispatch(showPOSGetReadyModal());
      return;
    }

    if (!validateFormsResult) {
      dispatch(setPaymentProcessing(false));
      dispatch(setCardFormValidationStatus(false));
      return;
    }

    // @isCardFormValid redux state uses to inform view details toggle button to expand when form is invalid
    const isCardFormValidationFailed = state.payment?.isCardFormValid;
    if (!isCardFormValidationFailed) {
      dispatch(setCardFormValidationStatus(true));
    }

    // TODO see if we can reset selectedPaymentType when payment has been made entirely with gift cards/vouchers
    if (
      (selectedPaymentType === paymentTypeConstants.CREDIT_CARD ||
      selectedPaymentType === paymentTypeConstants.PREPAID_CARD) &&
      !state.cardType?.name &&
      !isZeroOutstandingBalance
    ) {
      dispatch(logUnsupportedCardType(selectedPaymentType));
      dispatch({
        type: CARD_TYPE_MISSING,
      });
      dispatch(setPaymentProcessing(false));

      return;
    }

    if (isSavedPayment) {
      dispatch(setSavedPaymentFormsSubmittingTrue());
    } else if (selectedPaymentType === paymentTypeConstants.PREPAID_CARD) {
      dispatch(setPrepaidPaymentFormsSubmittingTrue());
    } else {
      dispatch(setPaymentFormsSubmittingTrue());
    }

    // Need to submit details form one last time...
    // in case user has edited anything since last details update
    let deliveryDetailsResponse;
    if (!deliveryDetailsResponse?.some(item => item.hasOwnProperty('error'))) {

      // APPLE PAY PAYMENTS
      if (type === paymentTypeConstants.APPLE_PAY) {
        dispatch(applePayPaymentInit());
        dispatch(setPaymentFormsSubmittingFalse());
        return;
      }

      // OTHER PAYMENTS
      await dispatch(submitAndHandlePayment({
        type,
      }));

      if (isSavedPayment) {
        dispatch(setSavedPaymentFormsSubmittingFalse());
      } else if (selectedPaymentType === paymentTypeConstants.PREPAID_CARD) {
        dispatch(setPrepaidPaymentFormsSubmittingFalse());
      } else {
        dispatch(setPaymentFormsSubmittingFalse());
      }

      const threeDSMethodRequired = getState().payment?.threeDSMethodInfo?.threeDSMethodRequired ?? false;
      if (!threeDSMethodRequired) {
        dispatch(setPaymentProcessing(false));
      }
    }
  }
};

export const giftOptionsChangeToPrepaid = initialValues => async (dispatch) => {
  dispatch(resetForm(PREPAID_CARD_DETAILS_FORM_ID, initialValues));
  dispatch(setSelectedPaymentType({ paymentType: paymentTypeConstants.PREPAID_CARD }));

  scrollToElement({
    id: paymentTypeConstants.PREPAID_CARD,
  });

  const prepaidCardEntry = document.querySelector('input[value="prepaidCard"]');
  if (prepaidCardEntry) {
    setTimeout(() => { prepaidCardEntry.focus(); }, 0);
  }
};

export const setSelectedSavedCard = id => ({
  type: SET_SELECTED_SAVED_CARD,
  id,
});

export const loadIovationScripts = () => async (dispatch, getState) => {
  const state = getState();

  if (env.isClientLocal) {
    return;
  }

  if (!state.user.deliveryPageLoaded) {
    return;
  }

  if (state.payment.iovationScriptsLoading) {
    return;
  }

  if (state.payment.iovationScriptsLoaded) {
    return;
  }

  if (state.payment.iovationScriptsFailed) {
    return;
  }

  await dispatch({
    type: IOVATION_SCRIPTS_LOADING,
  });

  let iovationSnareScript = 'https://ci-mpsnare.iovation.com/snare.js';

  if (window.publicJLSiteDomain?.includes('johnlewis.com')) {
    iovationSnareScript = 'https://mpsnare.iesnare.com/snare.js';
  }

  const onErrorCallback = () => {
    dispatch({
      type: IOVATION_SCRIPTS_FAILED,
    });
  };

  loadScript({
    url: '/security/iovation/config.js',
    onErrorCallback,
    onSuccessCallback: loadScript({
      url: iovationSnareScript,
      onErrorCallback,
      onSuccessCallback: () => {
        dispatch({
          type: IOVATION_SCRIPTS_LOADED,
        });
      },
      async: true,
    })(),
    async: true,
  })();
};

export const getPayments = () => ({
  type: GET_PAYMENTS,
  request: client => client({ path: URL_PAYMENTS, config: { method: 'GET' } }),
});

export const postPayments = () => ({
  type: POST_PAYMENTS,
  request: client => client({ path: URL_PAYMENTS, config: { method: 'POST' } }),
});

export const getGiftingPaymentDetails = () => ({
  type: GET_GIFTING_PAYMENT_DETAILS,
  request: client => client({ path: URL_GIFT_TOKENS, config: { method: 'POST' } }),
});
