/* eslint-disable css-modules/no-unused-class */
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Design System
import Textarea from 'jl-design-system/elements/textarea/Textarea';
import { Message } from 'jl-design-system/components/message/v2/Message';

// Types
import { AppDispatch, RootState } from 'types/RootState.types';
import { GiftMessageProps } from './GiftMessage.types';

// Config
import giftMessageState from './GiftMessage.state';
import { setDefaultGiftMessageValue } from '../../redux/actions/app/appActions';
import {
  getTextAreaLinesArray,
  isAscii,
  getNextCharsInfo,
  getCharsLeft,
  calcLinesAfterEdit,
  replaceNonAsciiExtended,
  parseCharCode,
} from '../../utils/string/textAreaUtils';

// Components
import BodyText from '../body-text';
import Container from '../container';

// Styles
import styles from './gift-message.scss';

const GiftMessage = ({
  ariaDescribedBy,
  classNameOverride,
  describedById,
  giftFormId = '',
  id,
  label,
  maxChars = 0,
  maxCharsLine = 0,
  maxLines = 0,
  name,
  onBlur = () => {},
  onChange = () => {},
  onFocus = () => {},
  value = '',
}: GiftMessageProps) => {
  const dispatch: AppDispatch = useDispatch();
  const {
    defaultGiftMessageValue = '',
    giftMessageValidationFailed,
  } = useSelector((state: RootState) => giftMessageState(state, giftFormId));

  const textAreaWrapperRef = useRef<HTMLDivElement>(null);
  const [textAreaElement, setTextAreaElement] = useState<HTMLTextAreaElement | null>(null);

  useEffect(() => {
    if (textAreaWrapperRef.current) {
      setTextAreaElement(textAreaWrapperRef.current.querySelector('textarea'));
    }
  }, [textAreaWrapperRef]);

  const [charsLeft, setCharsLeft] = useState<number>(maxChars);
  const [linesLeft, setLinesLeft] = useState<number>(maxLines);

  useEffect(() => {
    if (textAreaElement) {
      const linesArray = getTextAreaLinesArray({
        string: value,
        maxLines,
        maxCharsLine,
      });

      const initCharsLeft = getCharsLeft({
        textArea: textAreaElement,
        linesArray,
        maxChars,
        maxLines,
        maxCharsLine,
        isTextArea: true,
      });

      setCharsLeft(initCharsLeft);
      setLinesLeft(maxLines - linesArray.length);
    }
  }, [textAreaElement, value, maxLines, maxCharsLine, maxChars]);

  const onKeyDownHandler = (evt: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (!textAreaElement) return;

    const charCode = evt.key.toLowerCase();

    if (!charCode.includes('arrow')) {
      const isBackspace = charCode === 'backspace' || charCode === 'delete';
      const isTab = charCode === 'tab';
      const { prevChar, nextChar, newLine } = getNextCharsInfo(textAreaElement);
      const doNotAllowSpace = prevChar === ' ' || nextChar === ' ' || newLine;
      const isNotAscii = !isAscii({ value: charCode, extended: true });
      const insert = parseCharCode(charCode);

      const linesAfterEdit = calcLinesAfterEdit({
        textArea: textAreaElement,
        insert,
        maxCharsLine,
        isTextArea: true,
      });
      const willExceedMaxLines = linesAfterEdit > maxLines;

      if (
        (!charsLeft && !isBackspace && !isTab)
        || isNotAscii
        || (charCode === ' ' && doNotAllowSpace)
        || willExceedMaxLines
      ) {
        evt.preventDefault();
      }
    }
  };

  const onKeyUpHandler = (evt: React.KeyboardEvent<HTMLTextAreaElement>) => {
    const charCode = evt.key.toLowerCase();

    if (charCode.includes('arrow')) {
      calcStats();
    }
  };

  const onChangeHandler = (evt: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (!textAreaElement) return;

    textAreaElement.value = replaceNonAsciiExtended(textAreaElement.value);

    onChange(evt);
    calcStats();
    dispatch(setDefaultGiftMessageValue({ value: textAreaElement.value, giftFormId }));
  };

  const onFocusHandler = (evt: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    onFocus(evt);
    calcStats();
  };

  const onBlurHandler = (evt: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    onBlur(evt);
    calcStats();
  };

  const onPasteHandler = (evt: React.ClipboardEvent<HTMLTextAreaElement>) => {
    if (!textAreaElement) return;

    const clipboardData = replaceNonAsciiExtended(evt.clipboardData.getData('Text'));
    const willExceedMaxLines = calcLinesAfterEdit({
      textArea: textAreaElement,
      insert: clipboardData,
      maxCharsLine,
      isTextArea: true,
    }) > maxLines;

    evt.clipboardData.setData('Text', clipboardData);

    if (willExceedMaxLines) {
      evt.preventDefault();
    }
  };

  const getRemainingCharacters = (linesArray: any) => {
    if (!textAreaElement) return 0;

    return getCharsLeft({
      textArea: textAreaElement,
      linesArray,
      maxLines,
      maxChars,
      maxCharsLine,
      isTextArea: true,
    });
  };

  const calcStats = () => {
    if (!textAreaElement) return;

    const linesArray = getTextAreaLinesArray({
      string: textAreaElement.value,
      maxLines,
      maxCharsLine,
    });
    let charsLeft = getRemainingCharacters(linesArray);

    // Handle negative values because of iOS https://www.jlpit.com/jira/browse/MARV-4403
    const charsLeftIsNegative = charsLeft && charsLeft / Math.abs(charsLeft) < 0;
    if (charsLeftIsNegative) {
      // trim the characters that have gone over the limit and set the textarea value
      textAreaElement.value =
        textAreaElement.value.substring(Math.abs(charsLeft), textAreaElement.value.length).trim();

      // recalculate new characters left after truncating
      charsLeft = getRemainingCharacters(linesArray);
    }

    const linesLeftIsNegative = maxLines - linesArray.length < 0;

    setCharsLeft(charsLeftIsNegative ? 0 : charsLeft);
    setLinesLeft(linesLeftIsNegative ? 0 : maxLines - linesArray.length);
  };

  const role = 'text';

  return (
    <div className={classNameOverride}>
      {giftMessageValidationFailed && (
        <Container marginBottom="3">
          <Message
            body="Please check your gift message for extra characters or lines and try again."
            data-test="gift-message-validation-failed-error"
            title="Sorry, something went wrong"
            type="error"
          />
        </Container>
      )}

      <div ref={textAreaWrapperRef} className={styles.messageContainer}>
        <Container marginBottom="1">
          <Textarea
            ariaDescribedBy={ariaDescribedBy}
            defaultValue={defaultGiftMessageValue}
            extensionProps={{
              onChange: onChangeHandler,
              onKeyDown: onKeyDownHandler,
              onKeyUp: onKeyUpHandler,
              onFocus: onFocusHandler,
              onClick: onFocusHandler,
              onBlur: onBlurHandler,
              onPaste: onPasteHandler,
            }}
            id={id}
            label={label}
            maxLength={(maxChars || 0) + (maxLines - 1)}
            name={name}
          />
        </Container>
        <div id={describedById}>
          <BodyText marginBottom="1" role={role}>
            <strong>{charsLeft}</strong> characters and <strong>{linesLeft}</strong> lines remaining
          </BodyText>
        </div>
      </div>
      <BodyText>
        Delivery notes are only printed when the order is a gift. Prices will not be shown
      </BodyText>
    </div>
  );
};

export default GiftMessage;
