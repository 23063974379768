import { push } from 'connected-react-router';
import get from 'lodash/get';
import env from 'jl-design-system/utils/env/env';
import {
  SET_ACTIVE_TOOLTIP_ID,
  SET_PAGE_NOT_FOUND,
  ANALYTICS_PAGE_NOT_FOUND,
  ANALYTICS_LEAVE_CHECKOUT_MODAL_SHOWN,
  SET_SCROLL_TO_DELIVERY_DETAILS,
  SESSION_EXPIRED,
  SESSION_REFRESH,
  SHOW_DISABLE_SITE_SPINNER,
  HIDE_DISABLE_SITE_SPINNER,
  SHOW_EMPTY_BASKET_NOTIFICATION,
  LEAVE_CHECKOUT,
  CLOSE_LEAVE_CHECKOUT_MODAL,
  ANNOUNCE_SESSION_EXPIRED,
  ANNOUNCE_SESSION_EXPIRING,
  OPEN_SESSION_ENDED_MODAL,
  CLOSE_SESSION_ENDED_MODAL,
  ANALYTICS_EXPAND_BASKET_BUTTON_CLICKED,
  SHOW_MORE_SHOPS,
  MONETATE_SCRIPT_LOADED,
  MONETATE_SCRIPT_LOADING,
  MONETATE_SCRIPT_FAILED,
  GTM_SCRIPT_APPENDED,
  RECORD_IMPRESSIONS,
  GET_CONFIGURATION,
  LAZY_COMPONENT_LOADING,
  SET_GIFT_RECEIPT_SELECTED,
  SET_GIFT_RECEIPT_VALUE,
  SET_SCROLL_TO_PARTNERSHIP_DISCOUNT_TOGGLE,
  SET_TOAST_HAS_SHOWN,
} from '../../../constants/actionConstants';
import {
  URL_REFRESH_SESSION,
  URL_RECORD_IMPRESSIONS,
  URL_CONFIGURATION,
} from '../../../constants/endpointConstants';
import routeConstants from '../../../constants/routeConstants';
import triggerAnalytics from '../analytics/analyticsAction';
import { isApps } from '../../reducers/app/appReducer';
import { loadScript, appendScript } from '../../../utils';
import { getCookieGroupPermission } from '../../../utils/pecr/pecr';

export const getSessionTimeoutValues = state => ({
  sessionExpiryData: get(state, 'bff.sessionExpiryData'),
  sessionWarningAfterTime: get(state, 'config.sessionWarningAfterTime'),
});

export const openSessionEndedModal = () => ({
  type: OPEN_SESSION_ENDED_MODAL,
});

export const closeSessionEndedModal = () => ({
  type: CLOSE_SESSION_ENDED_MODAL,
});

export const announceSessionExpired = () => ({
  type: ANNOUNCE_SESSION_EXPIRED,
});

export const announceSessionExpiring = () => ({
  type: ANNOUNCE_SESSION_EXPIRING,
});

export const startLazyComponentLoad = componentName => ({
  type: `${LAZY_COMPONENT_LOADING}.LOADING`,
  componentName,
});

export const finishLazyComponentLoad = componentName => ({
  type: `${LAZY_COMPONENT_LOADING}.SUCCESS`,
  componentName,
});

export const setGiftReceiptSelected = ({ giftFormId, isSelected }) => ({
  type: SET_GIFT_RECEIPT_SELECTED,
  giftFormId,
  isSelected,
});

export const setDefaultGiftMessageValue = ({ giftFormId, value }) => ({
  type: SET_GIFT_RECEIPT_VALUE,
  giftFormId,
  value,
});

export const sessionExpiredRedirect = () => async (dispatch) => {
  dispatch({
    type: SESSION_EXPIRED,
  });
  dispatch(push({ pathname: routeConstants.SESSION_EXPIRED }));
};

export const markToastAsShown = () => ({
  type: SET_TOAST_HAS_SHOWN,
});

export const openLeaveCheckoutModal = pathname => async (dispatch) => {
  dispatch({
    type: LEAVE_CHECKOUT,
    baseRoutePath: pathname,
  });
  dispatch(push(routeConstants.LEAVE_CHECKOUT));
  dispatch(triggerAnalytics(ANALYTICS_LEAVE_CHECKOUT_MODAL_SHOWN));
};

export const closeLeaveCheckoutModal = () => ({
  type: CLOSE_LEAVE_CHECKOUT_MODAL,
});

export const refreshSession = () => ({
  type: SESSION_REFRESH,
  request: client => client({ path: URL_REFRESH_SESSION, config: { method: 'GET' } }),
});

export const setTooltipID = (tooltipID = '') => ({
  type: SET_ACTIVE_TOOLTIP_ID,
  tooltipID,
});

export const setPageNotFound = () => (dispatch) => {
  dispatch({
    type: SET_PAGE_NOT_FOUND,
  });
  dispatch(triggerAnalytics(ANALYTICS_PAGE_NOT_FOUND, { analytics: {} }));
};

export const setScrollToDeliveryDetails = value => ({
  type: SET_SCROLL_TO_DELIVERY_DETAILS,
  value,
});

export const setScrollToParnershipDiscountToggle = value => ({
  type: SET_SCROLL_TO_PARTNERSHIP_DISCOUNT_TOGGLE,
  value,
});

export const showDisableSiteSpinner = () => ({
  type: SHOW_DISABLE_SITE_SPINNER,
});

export const hideDisableSiteSpinner = () => ({
  type: HIDE_DISABLE_SITE_SPINNER,
});

export const showEmptyBasketNotification = () => ({
  type: SHOW_EMPTY_BASKET_NOTIFICATION,
});

export const triggerExpandBasketButtonClickedAnalytics = () => ({
  type: ANALYTICS_EXPAND_BASKET_BUTTON_CLICKED,
});

export const showMoreShops = shopsToShow => ({
  type: SHOW_MORE_SHOPS,
  shopsToShow,
});

export const loadMonetateScript = () => async (dispatch) => {
  await dispatch({
    type: MONETATE_SCRIPT_LOADING,
  });

  const url = window.env.MONETATE_SCRIPT_URL;
  loadScript({
    url,
    onErrorCallback: () => {
      dispatch({
        type: MONETATE_SCRIPT_FAILED,
      });
    },
    onSuccessCallback: () => {
      dispatch({
        type: MONETATE_SCRIPT_LOADED,
      });
    },
  })();
};

export const initMonetateScript = () => async (dispatch, getState) => {
  if (env.isClientNonProd) return;

  const state = getState();

  if (state.app.monetateScriptLoaded) return;

  if (state.app.monetateScriptLoading) return;

  if (state.app.monetateScriptFailed) return;

  const isApp = isApps(state);

  if (isApp) {
    if (state.bff.sessionHandoverComplete) dispatch(loadMonetateScript());

    return;
  }

  dispatch(loadMonetateScript());
};

export const appendGTMScript = () => (dispatch, getState) => {
  if (env.isClientNonProd) return;

  const state = getState();

  if (state.app.gtmScriptAppended) return;

  if (getCookieGroupPermission('Advertising') !== 'allow') return;

  let gtmHeadScript;

  if (window.publicJLSiteDomain?.includes('johnlewis')) {
    gtmHeadScript = "(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-NFBK4RMH');";
  } else {
    gtmHeadScript = "(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl+ '&gtm_auth=7gfPCWvurd_9AgHI_gFZyw&gtm_preview=env-3&gtm_cookies_win=x';f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-NFBK4RMH');";
  }

  appendScript(gtmHeadScript);

  dispatch({
    type: GTM_SCRIPT_APPENDED,
  });
};

export const recordImpressions = impressionId => (dispatch) => {
  const body = {
    impressionIds: [impressionId],
  };

  return dispatch({
    type: RECORD_IMPRESSIONS,
    impressionId,
    request: client => client({ path: URL_RECORD_IMPRESSIONS, config: { method: 'POST', body } }),
  });
};

export const getConfiguration = () => ({
  type: GET_CONFIGURATION,
  request: client => client({ path: URL_CONFIGURATION, config: { method: 'GET' } }),
});
