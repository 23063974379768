import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { go } from 'connected-react-router';

// Design System
import { PrimaryButton, SecondaryButton, TertiaryButton } from 'jl-design-system/elements/button/Button';

// Types
import { AppDispatch } from 'types/RootState.types';
import { PaymentCardDeleteProps } from './PaymentCardDelete.types';

// Config
import paymentCardDeleteState from './PaymentCardDelete.state';
import appConstants from '../../../../constants/appConstants';
import { showPaymentCardDeleteModal, deletePaymentCard } from '../../../../redux/actions/payment/paymentActions';
import { getLast4Digits } from '../../../../utils/helpers/cardDetails';
import cardLogos, { cardTypeText } from '../../../../utils/helpers/cardLogos';

// Components
import Container from '../../../container';
import Heading from '../../../heading';
import Modal from '../../../modal';

// Styles
import styles from './payment-card-delete.scss';

const PaymentCardDelete = ({
  disabled = false,
  routerActions = { go },
}: PaymentCardDeleteProps) => {
  const dispatch: AppDispatch = useDispatch();
  const {
    paymentCardDeleteApiCallActive,
    selectedPaymentCard,
    shouldShowPaymentCardDeleteModal,
  } = useSelector(paymentCardDeleteState);

  if (!selectedPaymentCard) {
    return null;
  }

  const { id, obfuscatedPan = '', type } = selectedPaymentCard;

  const closeModal = () => dispatch(routerActions.go(-1));
  const confirmDelete = () => dispatch(deletePaymentCard(id, returnLast4Digits()));
  const handleOnClick = () => dispatch(showPaymentCardDeleteModal());
  const returnLast4Digits = () => getLast4Digits(obfuscatedPan);

  return (
    <>
      <TertiaryButton
        data-test="payment-card-delete-button"
        disabled={disabled}
        onClick={handleOnClick}
        small
      >
        Delete card
      </TertiaryButton>

      {shouldShowPaymentCardDeleteModal && (
        <Modal
          disableControls={paymentCardDeleteApiCallActive}
          header="Delete payment card"
          isOpen
          onClose={closeModal}
          rootId={appConstants.ROOT}
        >
          <Container textAlign="center">
            <Heading data-test="payment-card-delete-confirm-message" marginBottom="2" tag="p" type="xs">
              Are you sure you want to delete this payment card?
            </Heading>

            {type && (
              <span className={styles.cardDetails}>
                <img
                  alt={type}
                  aria-hidden="true"
                  className={styles.logo}
                  src={cardLogos(type)}
                />
                <span data-test="payment-card-delete-card-details">
                  {cardTypeText(type)} ending in {returnLast4Digits()}
                </span>
              </span>
            )}

            <PrimaryButton
              className={styles.button}
              data-test="payment-card-delete-confirm"
              onClick={confirmDelete}
              submitting={paymentCardDeleteApiCallActive}
            >
              Yes, delete
            </PrimaryButton>
            <SecondaryButton
              className={styles.button}
              data-test="payment-card-delete-cancel"
              disabled={paymentCardDeleteApiCallActive}
              onClick={closeModal}
            >
              No, cancel
            </SecondaryButton>
          </Container>
        </Modal>
      )}
    </>
  );
};

export default PaymentCardDelete;
