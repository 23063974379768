import { replace } from 'connected-react-router';

// Types
import { AppDispatch, RootState } from 'types/RootState.types';

// Config
import { REBATCH_ORDER } from '../../constants/actionConstants';
import deliveryConstants from '../../constants/deliveryConstants';
import routeConstants from '../../constants/routeConstants';
import { showDisableSiteSpinner } from '../../redux/actions/app/appActions';
import { initDeliveryPage } from '../../redux/actions/delivery/deliveryActions';
import { rebatchOrder } from '../../redux/actions/edit-basket/editBasketActions';

export const handleInconsistentOrderFormState = () => async (dispatch: AppDispatch, getState: () => RootState) => {
  const address = getState()?.delivery?.confirmedDeliveryAddress;
  const selectedDeliveryChoiceId = getState()?.delivery?.selectedDeliveryChoiceId;

  dispatch(showDisableSiteSpinner());

  const response = await dispatch(rebatchOrder(address));

  if (response.type === `${REBATCH_ORDER}.SUCCESS`) {
    const currentLocation = getState()?.router?.location?.pathname ?? '';
    const isOnPayment = currentLocation.includes(routeConstants.PAYMENT)
      || currentLocation.includes(routeConstants.PAYMENT_POS_CREDIT_CALLBACK);
    const isOnDelivery = currentLocation.includes(routeConstants.DELIVERY);
    const isOnClickCollect = currentLocation.includes(routeConstants.CLICK_AND_COLLECT);

    if (isOnPayment) {
      if (selectedDeliveryChoiceId === deliveryConstants.CLICK_AND_COLLECT) {
        await dispatch(replace(routeConstants.CLICK_AND_COLLECT));
      } else {
        await dispatch(replace(routeConstants.DELIVERY_OPTIONS));
      }
    }
    // initDeliveryPage() is called automatically when you navigate to
    // /delivery or /click-and-collect from /payment
    // so if we are already on /delivery or /click-and-collect we need to call manually here
    if (isOnClickCollect || isOnDelivery) {
      await dispatch(initDeliveryPage({ refresh: true }));
    }
  }
};

export default handleInconsistentOrderFormState;
