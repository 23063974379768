import React from 'react';

// Design System
import AddressWithSearchForm from 'jl-design-system/components/address-with-search-form/AddressWithSearchForm';
import Link from 'jl-design-system/elements/link/Link';
import { getFormInitialValuesFromAddress } from 'jl-design-system/utils/address/address';

// Types
import { MyJohnLewisDetailsProps } from './MyJohnLewisDetails.types';

// Config
import { MYJL_ADDRESS } from '../../constants/actionConstants';
import routeConstants from '../../constants/routeConstants';
import getLoqateMocks from '../../non-prod-utils/utils/loqate/getLoqateMocks';
import { shouldMockLoqate } from '../../utils/address/addressHelpers';

// Components
import DisplayAddress from '../display-address/DisplayAddress';

// Styles
import styles from './my-john-lewis-details.scss';

const MyJohnLewisDetails = ({
  disablePostcodeSearch = false,
  formId = '',
  getFormConfig = () => {},
  loqateApiKey = '',
  onEnterManualAddress = () => {},
  onLoqateNetworkTimeoutCallback = () => {},
  onLoqateSearch = () => {},
  onMyJlAddressFormSubmit = () => {},
  onMyJlEditAddress = () => {},
  sessionId,
  showFullScreenSignup = false,
  simpleForm = false,
  title = '',
  triggerLoqateTimeoutEnterAddressManuallyAnalytics = () => {},
  userAddress = {},
}: MyJohnLewisDetailsProps) => {
  const {
    addressee,
    address,
    phoneNumber,
  } = userAddress;

  const initialValues = getFormInitialValuesFromAddress(userAddress);

  const link = showFullScreenSignup ?
    routeConstants.JOIN_MY_JL_EDIT :
    routeConstants.ORDER_CONFIRMATION_JOIN_MY_JL_EDIT;

  return (
    <div className={styles.container} data-test="my-john-lewis-details">

      <p>{title}</p>

      {simpleForm && (
        <React.Fragment>
          <DisplayAddress
            address={address}
            addressee={addressee}
            displayAddressTitle="Membership details"
            phoneNumber={phoneNumber}
          />

          <div className={styles.editLink}>
            <Link
              data-test="myjl-edit-address"
              onClick={onMyJlEditAddress}
              to={link}
            >
              Edit address
            </Link>
          </div>
        </React.Fragment>
      )}

      <AddressWithSearchForm
        addressId={MYJL_ADDRESS}
        combineExtraAddressLines
        destroyOnUnmount={false}
        disablePostcodeSearch={disablePostcodeSearch}
        formId={formId}
        getConfig={getFormConfig}
        getLoqateMocks={getLoqateMocks}
        initialValues={initialValues}
        jlpid={sessionId}
        loqateApiKey={loqateApiKey}
        maxAddressLines={3}
        multiFormValidation
        onAddressFormSubmit={onMyJlAddressFormSubmit}
        onEnterManualAddress={onEnterManualAddress}
        onLoqateNetworkTimeoutCallback={onLoqateNetworkTimeoutCallback}
        onLoqateSearch={onLoqateSearch}
        onLoqateTimeoutEnterAddressManuallyCallback={triggerLoqateTimeoutEnterAddressManuallyAnalytics}
        preventLoqateScroll
        replaceGbTerritoryCountryCodes={false}
        shouldMockLoqate={shouldMockLoqate()}
        useLoqateAddressSearch
        useV2Message
      />
    </div>
  );
};

export default MyJohnLewisDetails;
