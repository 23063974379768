import { createSelector } from 'reselect';

// Types
import { RootState } from 'types/RootState.types';

// Config
import { getIsApplicationSelector } from '../../redux/reducers/app/appSelector';
import { getConfirmationExpiredSignedInSelector, getConfirmationOrderSelector } from '../../redux/reducers/confirmation/orderConfirmationSelector';
import { getOrderFormItemsSelector } from '../../redux/reducers/order-form/orderFormSelector';
import {
  getIsGuestSelector,
  getShowMyJLAccountPromptSelector,
} from '../../redux/reducers/user/userSelector';
import { shouldApplyRewards } from '../../utils/payment/shouldApplyRewards';
import {
  getJoinMyJLFailedSelector,
  getJoinMyJLSuccessSelector,
} from '../../redux/reducers/create-account/createAccountSelector';

const getWillMakeRewardsAPICallSelector = (state: RootState) => shouldApplyRewards(state);

const sidebarState = createSelector(
  [
    getConfirmationExpiredSignedInSelector,
    getIsApplicationSelector,
    getIsGuestSelector,
    getOrderFormItemsSelector,
    getConfirmationOrderSelector,
    getWillMakeRewardsAPICallSelector,
    getJoinMyJLFailedSelector,
    getJoinMyJLSuccessSelector,
    getShowMyJLAccountPromptSelector,
  ],
  (
    expiredSignedIn,
    isApplication,
    isGuest,
    items,
    order,
    willMakeRewardsAPICall,
    joinMyJLFailed,
    joinMyJLSuccess,
    showMyJLAccountPrompt,
  ) => ({
    expiredSignedIn,
    isApplication,
    isGuest,
    items,
    order,
    willMakeRewardsAPICall,
    joinMyJLFailed,
    joinMyJLSuccess,
    showMyJLAccountPrompt,
  }),
);

export default sidebarState;
