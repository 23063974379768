import { format, isAfter } from 'date-fns';
// lodash
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import sortBy from 'lodash/sortBy';
//
import {
  GET_DELIVERY_PAGE,
  INIT_PAYMENT_PAGE,
  LOGIN_CREDS,
  GET_COLLECTION_POINT_DETAILS,
  GET_ORDER_CONFIRMATION_PAGE,
  USER_AGE_CHECK,
  GET_ADDRESS_BOOK,
  PUT_ADDRESS_BOOK_UPDATE,
  HAND_OVER_RESET,
  SET_PAYMENT_TYPE,
  PUT_DELIVERY_ADDRESS,
  LOGIN_RESET,
  CHANGE_DELIVERY_ADDRESS,
  SET_PARTNER_DISCOUNT,
  GET_PAYMENT_WALLET,
  DELIVERY_ADDRESS_SELECT_ADDRESS,
  EDIT_DELIVERY_ADDRESS,
  REBATCH_ORDER,
  SET_PARTNER_DISCOUNT_PROCESSING,
  SHOW_BATCHING_FAILURE_MODAL,
  SHOW_REMOVE_BATCHING_FAILURE_ERROR,
  AUTH0_CALLBACK,
  APPS_GUEST_HANDOVER,
  APPS_AUTHENTICATED_HANDOVER,
  DELETE_COLLECTION_POINT,
  BILLING_ADDRESS_SELECT_ADDRESS,
  HIDE_SELECTED_BILLING_ADDRESS_PREVIEW,
  USE_DELIVERY_ADDRESS_AS_BILLING_ADDRESS,
  PAYMENT_CARD_DELETE,
  POST_DELIVERY_PAGE,
  USE_DIFFERENT_RESIDENTIAL_ADDRESS,
  GET_SAVED_COLLECTION_POINTS,
  MOUNT_POS_CREDIT_ADDRESS_FORM,
  AUTH0_HEALTH_CHECK,
  USE_DELIVERY_ADDRESS_AS_RESIDENTIAL_ADDRESS,
  SET_SELECTED_DELIVERY_CHOICE_ID,
  AUTH0_CLAIM_ORDER_CALLBACK,
  AUTH0_REGISTRATION_CALLBACK,
  SIGN_OUT_USER,
  GET_AGE_VERIFICATION_SESSION,
  GET_AGE_VERIFICATION_RESULT,
  SET_AGE_VERIFICATION_LOADING_STATE,
  SET_AGE_VERIFICATION_CONFIRMATION_STATE,
} from '../../../constants/actionConstants';
import { mapSavedCollectionPoints } from '../click-and-collect/clickAndCollectReducer';
import { validateAddressBookRecords, validateAddress } from '../../../utils/addressBook/validateAddressBookRecords';
import userConstants from '../../../constants/userConstants';
import errorCodeConstants from '../../../constants/errorCodeConstants';
import deliveryConstants from '../../../constants/deliveryConstants';
import { ON_STATUS } from '../../../constants/partnerDiscountConstants';
import {
  getAddressFromFormValues,
  getBillingAddressForPaymentType,
} from '../../../utils/address/addressHelpers';
import { BILLING_ADDRESS_FORM_ID, RESIDENTIAL_ADDRESS_FORM_ID } from '../../../utils/form/configs/billingAddress';
import { DELIVERY_ADDRESS_FORM_ID } from '../../../utils/form/configs/deliveryAddress';
import { getNonGlobalError } from '../../../utils/error/parseError';
import initAddressBook from '../../../utils/addressBook/initAddressBook';
import mergeAddressBook from '../../../utils/addressBook/mergeAddressBookRecords';
import { isNameAndNumberComplete } from '../../../utils/addressBook/isAddressValid';
import getErrorMessageObject from '../../../utils/error/getErrorMessageObject';


export const INITIAL_STATE = {
  isSignedIn: false,
  isGuest: true,
  isSignedInWithData: false,
  addressBook: [],
  collectionPoints: [],
  savedPaymentCards: [],
  loyaltyType: '',
  membershipNumber: '',
  showMyJLAccountPrompt: false,
  isLoyaltyPendingApproval: false,
  showMarketingPreferencesPrompt: false,
  ageCheckDate: '',
  ageCheckError: undefined,
  ageCheckRequired: false,
  ageCheckSuccess: false,
  showAgeCheckModal: false,
  ageRestrictedProducts: [],
  ageRestrictedProductsAllowRemoval: false,
  userDob: '',
  userDobInvalid: false,
  ageCheckMinimumAge: '',
  selectedCollectionPoint: undefined,
  selectedBillingAddress: undefined,
  selectedDeliveryAddress: undefined,
  previousSelectedDeliveryAddress: undefined,
  userEnteredDeliveryAddress: undefined,
  confirmSelectedDeliveryAddress: false,
  canUseSelectedDeliveryAddress: true,
  defaultAddress: undefined,
  eligibleForPartnerDiscount: false,
  partnerDiscountCardNumber: '',
  partnerDiscountStatus: '',
  partnerDiscountEnabled: false,
  isPartnerDiscountApiCallActive: false,
  disableAutoApplyPartnerDiscount: false,
  applyPartnerDiscountError: undefined,
  fetchedPaymentWallet: false,
  postcodeOutOfAreaError: undefined,
  orderFormItemsLength: 0,
  isPartnerDiscountProcessing: false,
  showBatchingFailureModal: false,
  allItemsHaveBatchingFailure: false,
  batchingFailureItems: undefined,
  showRemoveBatchingFailureItemError: false,
  paymentWalletApiCallFailed: false,
  orderSaved: false,
  invalidPOSCreditAddress: undefined,
  shouldUseDeliveryAddressAsResidentialAddress: true,
  yotiCheckSuccess: false,
  showYotiConfirmationModal: false,
};

function getPostcodeOutOfAreaError(action) {
  const errorCode = get(action, 'error.code');

  if (
    errorCode === errorCodeConstants.POSTCODE_OUT_OF_AREA ||
    errorCode === errorCodeConstants.INVALID_ADDRESS_DETAILS
  ) {
    return getNonGlobalError({
      ...action,
      error: {
        code: errorCode,
      },
    });
  }

  return undefined;
}

function getEarliestDobFromProducts(products) {
  return products.reduce((acc, product = {}) => (
    !acc || product.dobNotLaterThan < acc ? product.dobNotLaterThan : acc
  ), 0);
}

function getOldestMinimumAge(products) {
  const age = products.reduce((acc, product = {}) => (
    product.minimumAge > acc ? product.minimumAge : acc
  ), 0);
  return age ? `${age}` : '';
}

function getAgeRestrictedProducts(products = [], dob) {
  const filteredProducts = products.filter(product => !product.bladedArticle);

  if (!dob) {
    return filteredProducts;
  }

  return filteredProducts.filter(product => isAfter(dob, product.dobNotLaterThan));
}

function getSignedInStatus(signedInStatus) {
  if (!signedInStatus) {
    return {};
  }

  // TODO pick one of these to use everywhere and remove the other
  const isGuest = signedInStatus === userConstants.GUEST;
  const isSignedIn = signedInStatus === userConstants.AUTHENTICATED;

  return {
    isGuest,
    isSignedIn,
  };
}

function getLoyaltyInfo(loyaltyMembershipInfo, isSignedIn) {
  if (!loyaltyMembershipInfo) {
    return {};
  }

  const { loyaltyType, myJohnLewisMembershipPending } = loyaltyMembershipInfo;
  const showMyJLAccountPrompt = isSignedIn && loyaltyType === userConstants.LOYALTY_NOT_A_MEMBER;

  return {
    showMyJLAccountPrompt,
    myJohnLewisMembershipPending,
    loyaltyType,
  };
}

export const getDefaultPaymentCardOrFirst =
  paymentWallet => paymentWallet.find(card => card.default) || paymentWallet[0];

const userReducer = (state = INITIAL_STATE, action = {}) => {

  switch (action.type) {

    case '@@redux-form/CHANGE': {
      const formId = get(action, 'meta.form', '');
      const field = get(action, 'meta.field');
      if (formId === BILLING_ADDRESS_FORM_ID) {
        if (field === 'id') {
          const selectedBillingAddress =
            state.addressBook?.find(addressRecord => addressRecord.id === action.payload);
          return {
            ...state,
            selectedBillingAddress,
          };
        }
      }

      if (formId === RESIDENTIAL_ADDRESS_FORM_ID) {
        if (field === 'id') {
          const selectedResidentialAddress =
            state.addressBook?.find(addressRecord => addressRecord.id === action.payload);
          return {
            ...state,
            selectedResidentialAddress,
            invalidPOSCreditAddress: undefined,
            shouldUseDeliveryAddressAsResidentialAddress: false,
          };
        }
      }

      if (formId === DELIVERY_ADDRESS_FORM_ID) {

        const fieldValue = action.payload;

        if (field === 'id') {
          const previousSelectedDeliveryAddress = state.selectedDeliveryAddress;
          const selectedDeliveryAddress =
            state.addressBook?.find(addressRecord => addressRecord.id === fieldValue);
          return {
            ...state,
            selectedDeliveryAddress,
            previousSelectedDeliveryAddress,
            selectedResidentialAddress: state.shouldUseDeliveryAddressAsResidentialAddress ?
              selectedDeliveryAddress :
              state.selectedResidentialAddress,
          };
        }

        return {
          ...state,
          userEnteredDeliveryAddress: {
            ...state.userEnteredDeliveryAddress,
            [field]: fieldValue,
          },
        };

      }

      return state;
    }

    case '@@redux-form/INITIALIZE': {
      const formId = get(action, 'meta.form', '');

      // TODO do we still need to do this?
      if (formId === DELIVERY_ADDRESS_FORM_ID) {
        return {
          ...state,
          userEnteredDeliveryAddress: {
            ...action.payload,
          },
        };
      }

      return state;
    }

    case `${AUTH0_CALLBACK}.SUCCESS`:
    case `${APPS_GUEST_HANDOVER}.SUCCESS`:
    case `${APPS_AUTHENTICATED_HANDOVER}.SUCCESS`:
    case `${LOGIN_CREDS}.SUCCESS`: {

      const result = action.result || {};
      const signedInStatus = getSignedInStatus(result.customer?.signedInStatus);
      const loyaltyInfo = getLoyaltyInfo(result.customer?.loyaltyMembershipInfo, signedInStatus.isSignedIn);

      return {
        ...state,
        email: result.customer?.email,
        ...signedInStatus,
        ...loyaltyInfo,
        showBatchingFailureModal: false,
      };
    }

    case `${USER_AGE_CHECK}.FAILED`: {

      const ageRestrictedProducts = getAgeRestrictedProducts(state.ageRestrictedProducts, action.dob);
      const ageRestrictedProductsCanRemove = ageRestrictedProducts.length < state.orderFormItemsLength;
      const errorTitleSuffix = ageRestrictedProducts.length === 1 ? 'this item' : 'these items';

      return {
        ...state,
        ageCheckError: ageRestrictedProductsCanRemove ? {
          code: errorCodeConstants.CLIENT_AGE_CHECK_FAILED,
          message: `You are not old enough to purchase ${errorTitleSuffix}`,
          body: 'Please remove this item so that you can continue to order your remaining item(s). Alternatively, you can return back to the basket.',
        } : {
          code: errorCodeConstants.CLIENT_AGE_CHECK_FAILED,
          message: 'You are not old enough to continue with your order',
          body: 'Please return to your basket and remove the age-restricted item(s).',
        },
        showAgeCheckModal: true,
        ageRestrictedProducts,
        ageRestrictedProductsCanRemove,
        ageCheckMinimumAge: getOldestMinimumAge(ageRestrictedProducts),
      };
    }

    case `${USER_AGE_CHECK}.SUCCESS`: {
      return {
        ...state,
        ageRestrictedProducts: [],
        ageCheckDate: '',
        ageCheckRequired: false,
        ageCheckError: undefined,
        ageCheckSuccess: true,
        showAgeCheckModal: false,
        ageCheckMinimumAge: '',
      };
    }

    case `${REBATCH_ORDER}.SUCCESS`: {
      const { addressBook } = initAddressBook(state, action);

      return {
        ...state,
        addressBook,
        showAgeCheckModal: false,
      };
    }

    case SET_PAYMENT_TYPE: {
      const { paymentType, shouldSetBillingAddress } = action;
      const { addressBook, savedPaymentCards } = state;

      const selectedBillingAddress = getBillingAddressForPaymentType({
        addressBook,
        paymentType,
        savedPaymentCards,
        shouldSetBillingAddress,
      });

      return {
        ...state,
        selectedBillingAddress: selectedBillingAddress || state.selectedBillingAddress,
      };
    }

    case `${POST_DELIVERY_PAGE}.SUCCESS`:
    case `${GET_DELIVERY_PAGE}.SUCCESS`: {
      const rawAddressBook = get(action.result, 'customer.addressBook', []);
      const partnerDiscount = get(action.result, 'partnerDiscountDetails', {});
      const eligibleForPartnerDiscount = get(action.result, 'partnerDiscountDetails.eligibleForPartnerDiscount');
      const partnerDiscountStatus = partnerDiscount.status;
      const okToEnablePartnerDiscount = eligibleForPartnerDiscount && partnerDiscountStatus === ON_STATUS;
      const partnerDiscountEnabled = state.disableAutoApplyPartnerDiscount ? false : okToEnablePartnerDiscount;

      const {
        addressBook,
        selectedDeliveryAddress,
        selectedAddressIneligibleError,
        canUseSelectedDeliveryAddress,
        confirmSelectedDeliveryAddress,
        internationalUnavailableDefaultedToSavedUKMessage,
      } = initAddressBook(state, action);

      const isSignedInWithData = addressBook.length > 0 && !isEmpty(rawAddressBook[0]);

      const result = action.result;
      const signedInStatus = getSignedInStatus(result.customer?.signedInStatus);
      const loyaltyInfo = getLoyaltyInfo(result.customer?.loyaltyMembershipInfo, signedInStatus.isSignedIn);

      const userDob = get(result, 'customer.dateOfBirth', '');
      const ageRestrictedProducts = getAgeRestrictedProducts(result.ageRestrictedProducts, userDob);

      const ageCheckDate = ageRestrictedProducts.length ?
        format(getEarliestDobFromProducts(ageRestrictedProducts), 'YYYY-MM-DD') : '';

      const userDobInvalid = (userDob && ageCheckDate) ? isAfter(userDob, ageCheckDate) : false;
      const ageCheckRequired = Boolean(ageCheckDate && (state.isGuest || (state.isSignedIn && !userDob)));
      const collectionPoints = get(action, 'result.customer.collectionPoints', []);
      const collectionPointsSaved = collectionPoints.length > 0;

      const postcodeOutOfAreaError =
        selectedDeliveryAddress?.unavailabilityError?.code === errorCodeConstants.POSTCODE_OUT_OF_AREA ?
          state.postcodeOutOfAreaError :
          undefined;

      return {
        ...state,
        ...loyaltyInfo,
        email: result.customer?.email,
        customerPreferences: action.result.customer?.preferences,
        eligibleForPartnerDiscount,
        partnerDiscountCardNumber: get(action.result, 'partnerDiscountDetails.partnerDiscountCardNumber', ''),
        partnerDiscountStatus,
        partnerDiscountEnabled,
        showClickAndCollectPriceWarning: get(action.result, 'partnerDiscountDetails.showClickAndCollectPriceWarning'),
        addressBook,
        defaultAddress: isSignedInWithData ? selectedDeliveryAddress : undefined,
        selectedDeliveryAddress,
        selectedAddressIneligibleError: state.postcodeOutOfAreaError ? undefined : selectedAddressIneligibleError,
        canUseSelectedDeliveryAddress,
        confirmSelectedDeliveryAddress,
        internationalUnavailableDefaultedToSavedUKMessage,
        isSignedInWithData,
        deliveryPageLoaded: true,
        userDob,
        userDobInvalid,
        ageRestrictedProducts,
        ageCheckDate,
        ageCheckRequired,
        showAgeCheckModal: !state.ageCheckSuccess && Boolean(userDobInvalid || ageCheckRequired),
        ageCheckMinimumAge: getOldestMinimumAge(ageRestrictedProducts),
        orderFormItemsLength: result.orderForm.items.length || 0,
        showBatchingFailureModal: false,
        ageRestrictedProductsInBasket: result.ageRestrictedProducts,
        collectionPoints: isEmpty(state.collectionPoints) ? collectionPoints : state.collectionPoints,
        collectionPointsSaved,
        postcodeOutOfAreaError,
        ...signedInStatus,
      };
    }

    case `${GET_SAVED_COLLECTION_POINTS}.SUCCESS`: {
      return {
        ...state,
        collectionPoints: mapSavedCollectionPoints(get(action, 'result.collectionPoints')),
        savedCollectionPointsLoaded: true,
      };
    }

    case `${SET_PARTNER_DISCOUNT}.LOADING`: {
      return {
        ...state,
        isPartnerDiscountApiCallActive: true,
        isPartnerDiscountProcessing: true,
        partnerDiscountEnabled: action.partnerDiscountEnabled,
        disableAutoApplyPartnerDiscount: !action.partnerDiscountEnabled,
      };
    }

    case `${SET_PARTNER_DISCOUNT}.FAILED`: {
      return {
        ...state,
        isPartnerDiscountApiCallActive: false,
        isPartnerDiscountProcessing: false,
        partnerDiscountEnabled: !action.partnerDiscountEnabled,
        disableAutoApplyPartnerDiscount: action.partnerDiscountEnabled,
        applyPartnerDiscountError: getNonGlobalError({
          ...action,
          error: {
            code: errorCodeConstants.APPLY_PARTNER_DISCOUNT_FAILED,
          },
        }),
      };
    }

    case `${SET_PARTNER_DISCOUNT}.SUCCESS`: {

      return {
        ...state,
        isPartnerDiscountApiCallActive: false,
        partnerDiscountEnabled: action.partnerDiscountEnabled,
        disableAutoApplyPartnerDiscount: !action.partnerDiscountEnabled,
      };
    }

    case `${SET_PARTNER_DISCOUNT_PROCESSING}.FALSE`: {
      return {
        ...state,
        isPartnerDiscountProcessing: false,
      };
    }

    case `${GET_COLLECTION_POINT_DETAILS}.SUCCESS`: {
      const collectionPoints = [...state.collectionPoints];
      const collectionPoint = get(action, 'result.collectionPoints[0]');

      if (collectionPoint) {
        const collectionPointIndex = collectionPoints?.findIndex(point => point.id === collectionPoint.id);

        const updatedCollectionPoint = {
          ...collectionPoints[collectionPointIndex],
          ...collectionPoint,
        };

        collectionPoints[collectionPointIndex] = updatedCollectionPoint;

        return {
          ...state,
          selectedCollectionPoint: updatedCollectionPoint,
          collectionPoints,
        };
      }

      return {
        ...state,
      };
    }

    case `${INIT_PAYMENT_PAGE}.SUCCESS`: {
      const confirmedDeliveryAddress = get(action, 'result.orderForm.deliveryAddress');
      const rawAddressBook = get(action.result, 'customer.addressBook', []);
      const mergedAddressBook = mergeAddressBook(state.addressBook, rawAddressBook);
      const addressBook = validateAddressBookRecords(mergedAddressBook);
      const isClickCollectOrder = !!get(action, 'result.orderForm.deliveries[0].fulfilment.collectionInfo');
      const signedInStatus = getSignedInStatus(action.result?.customer?.signedInStatus);
      const loyaltyInfo = getLoyaltyInfo(action.result?.customer?.loyaltyMembershipInfo, state.isSignedIn);

      // MARV-8747 handle the scenario where a signed in customer with no saved addresses has
      // an address book created for them during a failed/cancelled card payment
      const isSignedInWithData = addressBook.length > 0 && !isEmpty(rawAddressBook[0]);
      const addressBookNewlyCreated = state.addressBook.length === 0 && isSignedInWithData;

      const contactAddress = addressBook?.find(addressRecord => addressRecord.contact && addressRecord.address?.countryCode === 'GB');

      let selectedResidentialAddress;

      if (isClickCollectOrder) {
        selectedResidentialAddress = contactAddress;
      } else if (state.shouldUseDeliveryAddressAsResidentialAddress) {
        // use the customer's previously selected delivery address
        selectedResidentialAddress = confirmedDeliveryAddress;
      } else if (state.selectedResidentialAddress) {
        // use the customer's previously selected residential address
        selectedResidentialAddress = state.selectedResidentialAddress;
      } else if (contactAddress) {
        // use the customer's GB contact address as a last resort
        selectedResidentialAddress = contactAddress;
      }

      return {
        ...state,
        addressBook,
        residentialAddressIsDeliveryAddress: state.shouldUseDeliveryAddressAsResidentialAddress,
        selectedResidentialAddress,
        ...addressBookNewlyCreated ? {
          selectedBillingAddress: addressBook[0],
          isSignedInWithData,
        } : {
          selectedBillingAddress: state.selectedBillingAddress || contactAddress,
        },
        ...signedInStatus,
        ...loyaltyInfo,
        email: action.result?.customer?.email,
      };
    }

    case `${GET_PAYMENT_WALLET}.FAILED`: {
      return {
        ...state,
        fetchedPaymentWallet: true,
        paymentWalletApiCallFailed: true,
      };
    }

    case `${PAYMENT_CARD_DELETE}.FAILED`: {
      if (get(action, 'error.code') === errorCodeConstants.CLIENT_PAYMENT_CARD_NOT_DELETED) {
        return {
          ...state,
          fetchedPaymentWallet: false,
          savedPaymentCards: [],
        };
      }

      return state;
    }

    case `${PAYMENT_CARD_DELETE}.SUCCESS`:
    case `${GET_PAYMENT_WALLET}.SUCCESS`: {
      const { selectedPaymentType, addressBook } = state;

      const rawPaymentWallet = get(action.result, 'paymentWallet', []);
      const paymentWallet = sortBy(rawPaymentWallet, [o => (!o.default)]);
      const defaultSavedPaymentDetail = getDefaultPaymentCardOrFirst(paymentWallet) || {};
      const defaultBillingAddressId = defaultSavedPaymentDetail.addressId;

      let paymentCardAddressInAddressBook;

      if (!selectedPaymentType && defaultBillingAddressId) {
        paymentCardAddressInAddressBook =
          addressBook?.find(addressRecord => addressRecord.id === defaultBillingAddressId) || false;
      }

      const selectedBillingAddress = paymentCardAddressInAddressBook ||
        getBillingAddressForPaymentType({
          addressBook,
          paymentType: selectedPaymentType,
          savedPaymentCards: paymentWallet,
        });

      return {
        ...state,
        savedPaymentCards: paymentWallet,
        selectedBillingAddress,
        defaultSavedPaymentDetail,
        fetchedPaymentWallet: true,
        paymentWalletApiCallFailed: false,
      };
    }

    case `${GET_ORDER_CONFIRMATION_PAGE}.SUCCESS`: {
      // needed in scenarios where customer has left checkout and then returned
      const email = get(action, 'result.customer.email');

      return {
        ...state,
        email: state.email || email,
        showMarketingPreferencesPrompt: get(action, 'result.marketingPreferencesPromptVisible'),
      };
    }

    case `${PUT_DELIVERY_ADDRESS}.FAILED`: {

      if (get(action, 'error.code') === errorCodeConstants.CLIENT_HANDLE_SERVER_INTERNAL_ERROR) {
        return {
          ...state,
          selectedDeliveryAddress: state.previousSelectedDeliveryAddress,
          previousSelectedDeliveryAddress: undefined,
        };
      }

      const postcodeOutOfAreaError = getPostcodeOutOfAreaError(action);

      if (action.isSavedAddress && !!state.selectedDeliveryAddress) {
        const selectedDeliveryAddressId = get(state, 'selectedDeliveryAddress.id');
        const addressBook = get(state, 'addressBook', []).map((addressRecord) => {
          if (addressRecord.id === selectedDeliveryAddressId && postcodeOutOfAreaError) {
            return {
              ...addressRecord,
              notAvailableForDelivery: !!postcodeOutOfAreaError,
              unavailabilityError: {
                code: postcodeOutOfAreaError.code,
                message: "This address is disabled as you have an item in your order that's not eligible for delivery to this address.",
              },
            };
          }
          return addressRecord;
        });
        const selectedDeliveryAddress =
          addressBook?.find(addressRecord => addressRecord.id === selectedDeliveryAddressId);

        return {
          ...state,
          addressBook,
          selectedDeliveryAddress,
          selectedAddressIneligibleError: undefined,
          canUseSelectedDeliveryAddress: !selectedDeliveryAddress.notAvailableForDelivery && !postcodeOutOfAreaError,
          postcodeOutOfAreaError,
        };
      }

      return {
        ...state,
        selectedDeliveryAddress: action.address,
        selectedAddressIneligibleError: undefined,
        canUseSelectedDeliveryAddress: !postcodeOutOfAreaError,
        postcodeOutOfAreaError,
      };
    }

    case `${PUT_DELIVERY_ADDRESS}.SUCCESS`: {
      // TODO: Why do we persist selectedDeliveryAddress here?
      return {
        ...state,
        selectedDeliveryAddress: action.address,
        selectedAddressIneligibleError: undefined,
        postcodeOutOfAreaError: undefined,
        confirmSelectedDeliveryAddress: false,
        previousSelectedDeliveryAddress: undefined,
      };
    }

    case `${PUT_ADDRESS_BOOK_UPDATE}.SUCCESS`: {
      const rawAddressBook = get(action.result, 'addressBook', []);
      const mergedAddressBook = mergeAddressBook(state.addressBook, rawAddressBook);

      const addressBook = validateAddressBookRecords(mergedAddressBook);

      return {
        ...state,
        addressBook,
      };
    }

    case `${GET_ADDRESS_BOOK}.SUCCESS`: {
      const rawAddressBook = get(action.result, 'addressBook', []);
      const mergedAddressBook = mergeAddressBook(state.addressBook, rawAddressBook);
      const addressBook = validateAddressBookRecords(mergedAddressBook);
      const isSignedInWithData = addressBook?.length > 0;

      return {
        ...state,
        addressBook,
        isSignedInWithData,
      };
    }

    case `${AUTH0_REGISTRATION_CALLBACK}.SUCCESS`: {
      const loginResponse = action.result?.loginResponse || {};
      const signedInStatus = getSignedInStatus(loginResponse.customer?.signedInStatus);
      const loyaltyInfo = getLoyaltyInfo(loginResponse.customer?.loyaltyMembershipInfo, signedInStatus.isSignedIn);

      return {
        ...state,
        ...signedInStatus,
        ...loyaltyInfo,
        auth0RegistrationSuccess: true,
      };
    }

    case `${AUTH0_CLAIM_ORDER_CALLBACK}.SUCCESS`: {
      const result = action.result || {};
      const signedInStatus = getSignedInStatus(result.customer?.signedInStatus);
      const loyaltyInfo = getLoyaltyInfo(result.customer?.loyaltyMembershipInfo, signedInStatus.isSignedIn);

      return {
        ...state,
        ...signedInStatus,
        ...loyaltyInfo,
        auth0ClaimOrderCallbackSuccess: true,
        orderSaved: true,
        validSession: result.validSession,
        claimOrderCallbackHappened: true,
      };
    }

    case `${AUTH0_CLAIM_ORDER_CALLBACK}.FAILED`: {
      return {
        ...state,
        auth0CallbackError: getErrorMessageObject({ error: action.error }),
        orderSaved: false,
        validSession: ![
          errorCodeConstants.SAVE_ORDER_TECHNICAL_ERROR_NO_SESSION,
          errorCodeConstants.SAVE_ORDER_INVALID_LINK,
        ].includes(action.error.code),
        claimOrderCallbackHappened: true,
      };
    }

    case `${AUTH0_HEALTH_CHECK}.FAILED`: {
      return {
        ...state,
        auth0CallbackError: getErrorMessageObject({ error: action.error }),
      };
    }

    case BILLING_ADDRESS_SELECT_ADDRESS: {
      return {
        ...state,
        previewSelectedBillingAddress: true,
      };
    }

    case USE_DELIVERY_ADDRESS_AS_BILLING_ADDRESS: {
      return {
        ...state,
        previewSelectedBillingAddress: false,
      };
    }

    case HIDE_SELECTED_BILLING_ADDRESS_PREVIEW: {
      return {
        ...state,
        previewSelectedBillingAddress: false,
      };
    }

    case MOUNT_POS_CREDIT_ADDRESS_FORM: {
      const invalidPOSCreditAddress = state.selectedResidentialAddress;

      return {
        ...state,
        invalidPOSCreditAddress,
        selectedResidentialAddress: undefined,
      };
    }

    case USE_DIFFERENT_RESIDENTIAL_ADDRESS: {
      return {
        ...state,
        selectedResidentialAddress: undefined,
        invalidPOSCreditAddress: undefined,
        shouldUseDeliveryAddressAsResidentialAddress: false,
      };
    }

    case USE_DELIVERY_ADDRESS_AS_RESIDENTIAL_ADDRESS: {
      return {
        ...state,
        selectedResidentialAddress: action.confirmedDeliveryAddress,
        invalidPOSCreditAddress: undefined,
        shouldUseDeliveryAddressAsResidentialAddress: true,
      };
    }

    case DELIVERY_ADDRESS_SELECT_ADDRESS: {
      const addressValues = {
        ...state.userEnteredDeliveryAddress,
        ...action.address,
      };

      if (!isNameAndNumberComplete(state.userEnteredDeliveryAddress)) {
        return state;
      }

      return {
        ...state,
        selectedDeliveryAddress: validateAddress(getAddressFromFormValues(addressValues)),
      };
    }

    case CHANGE_DELIVERY_ADDRESS: {
      return {
        ...state,
        selectedDeliveryAddress: undefined,
        selectedAddressIneligibleError: undefined,
        postcodeOutOfAreaError: undefined,
        canUseSelectedDeliveryAddress: true,
      };
    }

    case EDIT_DELIVERY_ADDRESS: {
      return {
        ...state,
        selectedDeliveryAddress: undefined,
        postcodeOutOfAreaError: undefined,
      };
    }

    case LOGIN_RESET:
      return {
        ...state,
        isSignedInWithData: false,
        addressBook: [],
        collectionPoints: [],
        savedPaymentCards: [],
        showMarketingPreferencesPrompt: false,
        selectedCollectionPoint: undefined,
        selectedBillingAddress: undefined,
        selectedDeliveryAddress: undefined,
        userEnteredDeliveryAddress: undefined,
        confirmSelectedDeliveryAddress: false,
        canUseSelectedDeliveryAddress: true,
        defaultAddress: undefined,
        internationalUnavailableDefaultedToSavedUKMessage: undefined,
        eligibleForPartnerDiscount: false,
        partnerDiscountCardNumber: '',
        partnerDiscountStatus: '',
        partnerDiscountEnabled: false,
        isPartnerDiscountApiCallActive: false,
        disableAutoApplyPartnerDiscount: false,
        applyPartnerDiscountError: undefined,
        fetchedPaymentWallet: false,
        postcodeOutOfAreaError: undefined,
        selectedAddressIneligibleError: undefined,
      };

    case SHOW_BATCHING_FAILURE_MODAL:
      return {
        ...state,
        allItemsHaveBatchingFailure: action.allItemsHaveBatchingFailure,
        batchingFailureItems: action.batchingFailureItems,
        showBatchingFailureModal: true,
      };

    case SHOW_REMOVE_BATCHING_FAILURE_ERROR:
      return {
        ...state,
        showRemoveBatchingFailureItemError: true,
      };

    case `${DELETE_COLLECTION_POINT}.SUCCESS`:
      return {
        ...state,
        collectionPoints: mapSavedCollectionPoints(get(action, 'result.collectionPoints', [])),
      };

    case HAND_OVER_RESET:
      return INITIAL_STATE;

    case SET_SELECTED_DELIVERY_CHOICE_ID:
      if (action.id === deliveryConstants.CLICK_AND_COLLECT) {
        return {
          ...state,
          shouldUseDeliveryAddressAsResidentialAddress: false,
          selectedResidentialAddress: undefined,
        };
      }

      return state;

    case `${SIGN_OUT_USER}.LOADING`: {
      return {
        ...state,
        signOutApiCallActive: true,
      };
    }

    case `${SIGN_OUT_USER}.FAILED`: {
      return {
        ...state,
        signOutApiCallActive: false,
      };
    }

    case `${GET_AGE_VERIFICATION_SESSION}.LOADING`:
      return {
        ...state,
        getAgeVerificationSessionApiCallActive: true,
        yotiLoading: true,
      };

    case `${GET_AGE_VERIFICATION_SESSION}.SUCCESS`: {
      const { sessionId, sdkId } = action.result ?? {};

      return {
        ...state,
        getAgeVerificationSessionApiCallActive: false,
        yotiLoading: false,
        yotiQueryParams: `sessionId=${sessionId}&sdkId=${sdkId}`,
        yotiSessionId: sessionId,
      };
    }

    case SET_AGE_VERIFICATION_LOADING_STATE:
      return {
        ...state,
        yotiLoading: action.loadingState,
      };

    case SET_AGE_VERIFICATION_CONFIRMATION_STATE:
      return {
        ...state,
        showYotiConfirmationModal: action.confirmationState,
      };

    case `${GET_AGE_VERIFICATION_RESULT}.FAILED`:
      return {
        ...state,
        getAgeVerificationSessionApiCallActive: false,
        yotiError: action.error.code,
        yotiLoading: true,
      };

    case `${GET_AGE_VERIFICATION_RESULT}.LOADING`:
      return {
        ...state,
        getAgeVerificationSessionApiCallActive: true,
        yotiError: '',
        yotiLoading: true,
      };

    case `${GET_AGE_VERIFICATION_RESULT}.SUCCESS`:
      return {
        ...state,
        getAgeVerificationSessionApiCallActive: false,
        showYotiConfirmationModal: true,
        yotiError: '',
        yotiCheckSuccess: true,
        yotiLoading: true,
      };

    default:
      return state;
  }

};

export default userReducer;
